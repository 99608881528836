import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Auth from './Auth';

class NavbarComponent extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
        this.state = {
            isOpen: false,
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    logout() {
        Auth.logout();
        this.props.history.replace('/login');
    }

    render() {
        return (
            <Navbar color="light" light expand="md">
                <NavbarToggler right onClick={this.toggle} />
                <NavbarBrand href="/user">BulldozAIR Admin</NavbarBrand>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink activeClassName="active" className="nav-link" to="/user">
                                Users
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" className="nav-link" to="/organization">
                                Teams
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" className="nav-link" to="/billing_group">
                                Billing Groups
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" className="nav-link" to="/report">
                                Report Builder
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" className="nav-link" to="/tools">
                                Tools
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" className="nav-link" to="/stats">
                                Stats
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink activeClassName="active" className="nav-link" to="/settings">
                                Settings
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <Link activeClassName="active" className="nav-link" onClick={this.logout}>
                                Logout
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default withRouter(NavbarComponent);

import React, { Component } from 'react';
import {
    Container,
    Form,
    FormGroup,
    Label,
    Col,
    Input,
    Row,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Button,
} from 'reactstrap';
import Auth from './Auth';
import { withRouter } from 'react-router';
import NotificationSystem from 'react-notification-system';

class LoginForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.login}>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        value={this.props.email}
                        id="email"
                        placeholder="Email"
                        onChange={(e) => this.props.update('email', e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                        type="password"
                        name="password"
                        value={this.props.password}
                        id="password"
                        placeholder="Password"
                        onChange={(e) => this.props.update('password', e.target.value)}
                    />
                </FormGroup>
                <Button color="primary" block>
                    Login
                </Button>
            </Form>
        );
    }
}

class TwoFactorForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.loginTwoFactor}>
                <FormGroup>
                    <Label for="password">Two Factor 6-digits code</Label>
                    <Input
                        type="text"
                        autoFocus
                        name="two-factor"
                        value={this.props.twoFactor}
                        id="password"
                        placeholder="Password"
                        onChange={(e) => this.props.update('twoFactorCode', e.target.value)}
                    />
                </FormGroup>
                <Button color="primary" block>
                    Verify
                </Button>
            </Form>
        );
    }
}

class LoginComponent extends Component {
    constructor(props) {
        super(props);

        this._notificationSystem = null;
        this.state = {
            email: '',
            password: '',
            twoFactorCode: '',
            twoFactorJwt: null,
            displayTwoFactor: false,
        };

        this.update = this.update.bind(this);
        this.login = this.login.bind(this);
        this.loginTwoFactor = this.loginTwoFactor.bind(this);
    }

    login(e) {
        e.preventDefault();
        Auth.login({
            email: this.state.email,
            password: this.state.password,
            device: {
                name: window.navigator.appCodeName,
                platform: 'WEB',
                os: window.navigator.oscpu,
                app_version: 'admin_tool_web',
            },
        })
            .then((user) => {
                if (user.two_factor_jwt) {
                    this.setState({
                        deviceId: user.device ? user.device.id : undefined,
                        displayTwoFactor: true,
                        twoFactorJwt: user.two_factor_jwt,
                    });
                } else {
                    this.redirect();
                }
            })
            .catch(() => {
                this._notificationSystem.addNotification({
                    message: `Failed to login. Invalid Email/Password.`,
                    level: 'error',
                });
            });
    }

    loginTwoFactor(e) {
        e.preventDefault();
        Auth.submitTwoFactorCode({
            code: this.state.twoFactorCode,
            jwt: this.state.twoFactorJwt,
            device: {
                id: this.state.deviceId,
                name: window.navigator.appCodeName,
                platform: 'WEB',
                os: window.navigator.oscpu,
                app_version: 'admin_tool_web',
            },
        })
            .then(() => {
                this.redirect();
            })
            .catch((err) => {
                console.log(err);
                this._notificationSystem.addNotification({
                    message: `Failed to login. Invalid 2FA code.`,
                    level: 'error',
                });
            });
    }

    redirect() {
        this.props.history.replace('/');
    }

    update(field, value) {
        this.setState({
            [field]: value,
        });
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }

    render() {
        return (
            <div className="background-bz auth-wrapper">
                <Container>
                    <Row className="vertical-offset-100">
                        <Col md={{ size: 4, offset: 4 }}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center">
                                        <h2>Login</h2>
                                    </CardTitle>
                                    <CardText>
                                        {this.state.displayTwoFactor === false && (
                                            <LoginForm
                                                email={this.state.email}
                                                password={this.state.password}
                                                update={this.update}
                                                login={this.login}
                                            />
                                        )}

                                        {this.state.displayTwoFactor && (
                                            <TwoFactorForm
                                                twoFactorCode={this.state.twoFactorCode}
                                                loginTwoFactor={this.loginTwoFactor}
                                                update={this.update}
                                            />
                                        )}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem ref="notificationSystem" />
                </Container>
            </div>
        );
    }
}

export default withRouter(LoginComponent);

import axios from 'axios';

export default {
    getByUser: function (email) {
        return axios
            .get(process.env.REACT_APP_SERVER_URL + '/admin/form_templates?email=' + email + '&take=100000')
            .then((data) => data.data);
    },

    getByTeam: function (teamId) {
        return axios
            .get(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/form_templates?take=1000000')
            .then((data) => data.data);
    },

    addToTeam: function (formTemplateId, teamId) {
        return axios
            .post(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/form_templates/' + formTemplateId)
            .then((data) => data.data);
    },

    removeFromTeam: function (formTemplateId, teamId) {
        return axios
            .delete(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/form_templates/' + formTemplateId)
            .then((data) => data.data);
    },
};

import React, { Component } from 'react';
import { Container, Row, Col, Label, Input, Button } from 'reactstrap';

class ReportTemplateSelect extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }

    render() {
        var listOptions = this.props.reportTemplates.map((reportTemplate) => {
            return (
                <option key={reportTemplate.id} value={reportTemplate.id}>
                    {reportTemplate.title}
                </option>
            );
        });

        return (
            <div>
                <Label htmlFor="templateSelect">Please select a report :</Label>
                <Input
                    type="select"
                    id="templateSelect"
                    value={this.props.selectedTemplateId}
                    onChange={this.handleSelect}
                >
                    <option value="null">---------------</option>
                    {listOptions}
                </Input>
            </div>
        );
    }

    handleSelect(event) {
        this.props.selectReportTemplate(event.target.value);
    }
}

export default ReportTemplateSelect;

import axios from 'axios';

export default {
    getByUser: function (email) {
        return axios
            .get(process.env.REACT_APP_SERVER_URL + '/admin/contact_books?email=' + email)
            .then((data) => data.data);
    },

    getByTeam: function (teamId) {
        return axios
            .get(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/contact_books')
            .then((data) => data.data);
    },

    addToTeam: function (contactBookId, teamId) {
        return axios
            .post(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/contact_books/' + contactBookId)
            .then((data) => data.data);
    },

    removeFromTeam: function (contactBookId, teamId) {
        return axios
            .delete(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/contact_books/' + contactBookId)
            .then((data) => data.data);
    },
};

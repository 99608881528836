import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SearchTeamComponent extends Component {
    render() {
        return (
            <div className="element-wrapper">
                <h6 className="element-header">Weekly Stats</h6>

                <div className="controls-above-table">
                    <div className="row">
                        <div className="col-sm-6">
                            <button className="btn btn-sm btn-secondary">Download CSV</button>
                        </div>
                    </div>
                </div>

                <div className="element-box">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="element-search">
                                <input
                                    placeholder="Type to search for a team..."
                                    type="text"
                                    onChange={(e) => this.props.search(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-lightborder dataTable">
                            <thead>
                                <tr>
                                    <th className="sorting_asc">Team</th>
                                    <th>Blocks</th>
                                    <th>Notes</th>
                                    <th>Projects</th>
                                    <th>Stats</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.companies.map((company, index) => (
                                    <tr key={company.item.id}>
                                        <td className="nowrap">
                                            {company.item.organization_title} - {company.item.title}
                                        </td>
                                        <td>
                                            <div className="el-tablo highlight">
                                                <div className="value">{company.this_week.blocks_count}</div>
                                                <div
                                                    className={
                                                        company.variation.blockVariation >= 0
                                                            ? 'trending trending-up-basic'
                                                            : 'trending trending-down-basic'
                                                    }
                                                >
                                                    <span>{company.variation.blockVariation}%</span>
                                                    <i
                                                        className={
                                                            company.variation.blockVariation >= 0
                                                                ? 'os-icon os-icon-arrow-2-up'
                                                                : 'os-icon os-icon-arrow-2-down'
                                                        }
                                                    ></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="el-tablo highlight">
                                                <div className="value">{company.this_week.notes_count}</div>
                                                <div
                                                    className={
                                                        company.variation.noteVariation >= 0
                                                            ? 'trending trending-up-basic'
                                                            : 'trending trending-down-basic'
                                                    }
                                                >
                                                    <span>{company.variation.noteVariation}%</span>
                                                    <i
                                                        className={
                                                            company.variation.noteVariation >= 0
                                                                ? 'os-icon os-icon-arrow-2-up'
                                                                : 'os-icon os-icon-arrow-2-down'
                                                        }
                                                    ></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="el-tablo highlight">
                                                <div className="value">{company.this_week.projects_count}</div>
                                                <div
                                                    className={
                                                        company.variation.projectVariation >= 0
                                                            ? 'trending trending-up-basic'
                                                            : 'trending trending-down-basic'
                                                    }
                                                >
                                                    <span>{company.variation.projectVariation}%</span>
                                                    <i
                                                        className={
                                                            company.variation.projectVariation >= 0
                                                                ? 'os-icon os-icon-arrow-2-up'
                                                                : 'os-icon os-icon-arrow-2-down'
                                                        }
                                                    ></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Link to={'/stats/teams/' + company.item.id}>
                                                <button className="mr-2 mb-2 btn btn-primary btn-rounded" type="button">
                                                    See more
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchTeamComponent;

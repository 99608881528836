import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory as createHistory } from 'history';
import Root from './Root';

import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/css/main.css';
import './theme/css/custom.css';

const history = createHistory();

// Render the main component into the dom
render(<Root history={history} />, document.getElementById('root'));

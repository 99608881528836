import React, { Component } from 'react';
import update from 'immutability-helper';
import { Alert, Row, Col, Button, FormGroup, Input, Table } from 'reactstrap';
import Team from '../api/Team.js';
import Infinite from 'react-infinite';

class TeamUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: props.users,
            elements: this.buildElements(props.users, props.isAllowedToEditCurrentTeam),
            newEmails: '',
            isInfiniteLoading: false,
            infiniteLoadBeginEdgeOffset: 200,
        };
        this.handleChange = this.handleChange.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            users: nextProps.users,
            elements: this.buildElements(nextProps.users, nextProps.isAllowedToEditCurrentTeam),
            infiniteLoadBeginEdgeOffset: 200,
        });
    }

    addUsers() {
        var emailsUnfiltered = this.state.newEmails.split('\n');
        var emails = [];

        // remove useless empty rows, keep only valid emails
        for (var i = 0; i < emailsUnfiltered.length; i++) {
            if (this._validateEmail(emailsUnfiltered[i])) {
                emails.push(emailsUnfiltered[i]);
            }
        }

        return Team.addUsers(this.props.team.id, emails).then((results) => {
            var newUsers = [];
            var failedUsers = '';

            results.forEach((result) => {
                if (result.error) {
                    this.props.notificationSystem.addNotification({
                        message: `Fail to add user ${result.email} ... Maybe the user is already in the team/or the user does not exist.`,
                        level: 'error',
                    });
                    failedUsers += result.email + '\n';
                } else {
                    newUsers.push(result);
                }
            });

            const newState = update(this.state, {
                users: { $push: newUsers },
                newEmails: { $set: failedUsers },
            });

            this.setState(newState);

            this.props.replaceUsers(newUsers);
        });
    }

    _validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    deleteUser(index, userId) {
        return Team.deleteUser(this.props.team.id, userId).then(() => {
            const newState = update(this.state, {
                users: { $splice: [[index, 1]] },
            });

            this.setState(newState);

            this.props.replaceUsers(newState.users);
        });
    }

    handleChange(event) {
        this.setState({ newEmails: event.target.value });
    }

    handleInfiniteLoad() {
        this.setState({
            isInfiniteLoading: true,
        });

        Team.getUsers(this.props.team.id, 50, this.state.users.length).then((newUsers) => {
            const newState = update(this.state, {
                users: { $push: newUsers },
                elements: { $push: this.buildElements(newUsers, this.props.isAllowedToEditCurrentTeam) },
                isInfiniteLoading: { $set: false },
            });

            if (newUsers.length === 0) {
                newState.infiniteLoadBeginEdgeOffset = undefined;
            }

            this.setState(newState);
        });
    }

    elementInfiniteLoad() {
        return (
            <tr>
                <td>Loading...</td>
            </tr>
        );
    }

    buildElements(newUsers, isAllowedToEditCurrentTeam) {
        var listUsers = newUsers.map((user, index) => {
            return (
                <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>
                        {isAllowedToEditCurrentTeam && (
                            <Button outline color="danger" onClick={() => this.deleteUser(index, user.id)}>
                                Delete
                            </Button>
                        )}
                    </td>
                </tr>
            );
        });

        return listUsers;
    }

    render() {
        const { isAllowedToEditCurrentTeam } = this.props;
        return (
            <div>
                <Row>
                    <Col sm="6">
                        {!isAllowedToEditCurrentTeam && (
                            <Alert color="primary" className="mb-2">
                                This team is a default team and cannot be edited.
                            </Alert>
                        )}
                        <Infinite
                            elementHeight={63}
                            containerHeight={400}
                            preloadBatchSize={this.state.elements.length * 63}
                            infiniteLoadBeginEdgeOffset={this.state.infiniteLoadBeginEdgeOffset}
                            onInfiniteLoad={this.handleInfiniteLoad}
                            loadingSpinnerDelegate={this.elementInfiniteLoad()}
                            isInfiniteLoading={this.state.isInfiniteLoading}
                        >
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>{this.state.elements}</tbody>
                            </Table>
                        </Infinite>
                    </Col>
                    {isAllowedToEditCurrentTeam && (
                        <Col sm="6">
                            <FormGroup row>
                                <Col sm={10}>
                                    <Input
                                        type="textarea"
                                        name="text"
                                        id="users"
                                        value={this.state.newEmails}
                                        onChange={this.handleChange}
                                        placeholder="Add users separated by new lines"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup check row>
                                <Col sm={{ size: 10 }}>
                                    <Button outline color="success" onClick={this.addUsers}>
                                        Add Users
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

export default TeamUserList;

import React, { Component } from 'react';

import User from '../api/User.js';
import debounce from 'debounce';

import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

class UserSearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            textInput: '',
            users: [],
        };

        this.theme = {
            input: 'form-control react-autosuggest__input',
            container: 'react-autosuggest__container',
            containerOpen: 'react-autosuggest__container--open',
            inputOpen: 'react-autosuggest__input--open',
            inputFocused: 'react-autosuggest__input--focused',
            suggestionsContainer: 'react-autosuggest__suggestions-container',
            suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
            suggestionsList: 'react-autosuggest__suggestions-list',
            suggestion: 'react-autosuggest__suggestion',
            suggestionFirst: 'react-autosuggest__suggestion--first',
            suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
            sectionContainer: 'react-autosuggest__section-container',
            sectionContainerFirst: 'react-autosuggest__section-container--first',
            sectionTitle: 'react-autosuggest__section-title',
        };

        if (props.size === 'lg') {
            this.theme.input += ' form-control-lg';
        }

        this._search = this._search.bind(this);
        this.updateInput = this.updateInput.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onChange = this.onChange.bind(this);

        // ensure that the function search is not called
        // more than 1 time per 200ms
        this.search = debounce(this._search, 200, false);
    }

    _search(terms) {
        if (this._valideUuid(terms)) {
            User.getById(terms).then((user) => {
                this.setState({
                    users: [user],
                });
            });
        } else {
            User.search(terms, this.props.take).then((users) => {
                this.setState({
                    users: users,
                });
            });
        }
    }

    _valideUuid(uuid) {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);
    }

    updateInput(terms) {
        this.setState({ textInput: terms });
        if (terms.length > 2) {
            this.search(terms);
        } else {
            this.setState({ users: [] });
        }
    }

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex }) {
        this.props.userChanged(suggestion);
    }

    renderSuggestion(suggestion, { query }) {
        const suggestionText = suggestion.email;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);

        return (
            <span className={'suggestion-content ' + suggestion.twitter}>
                <span className="name">
                    {parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : null;

                        return (
                            <span className={className} key={index}>
                                {part.text}
                            </span>
                        );
                    })}
                </span>
            </span>
        );
    }

    getSuggestionValue(suggestion) {
        return suggestion.email;
    }

    onChange(event, { newValue, method }) {
        this.setState({
            textInput: newValue,
        });
        if (newValue == '') {
            if (this.props.userCleared) {
                this.props.userCleared();
            }
        }
    }

    render() {
        const inputProps = {
            placeholder: 'Search by email...',
            value: this.state.textInput,
            onChange: this.onChange,
        };

        return (
            <div>
                <Autosuggest
                    suggestions={this.state.users}
                    onSuggestionsFetchRequested={(e) => this.updateInput(e.value)}
                    onSuggestionsClearRequested={(e) => this.setState({ users: [] })}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionSelected={this.onSuggestionSelected}
                    inputProps={inputProps}
                    theme={this.theme}
                />
            </div>
        );
    }
}

export default UserSearchBar;

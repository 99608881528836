import React, { Component } from 'react';

import { Row, Col, Button, FormGroup, Label, Input, Table } from 'reactstrap';
import NotificationSystem from 'react-notification-system';

import Tools from '../api/Tools';

import loaderSvg from '../../theme/img/three-dots.svg';

class UserBatchComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            insertedUsers: [],
            selectedDemoProject: 'construction_fr',
            saving: false,
        };

        this._notificationSystem = null;

        this._handleFileChange = this._handleFileChange.bind(this);
        this.createUsers = this.createUsers.bind(this);
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }

    _handleFileChange(e) {
        e.preventDefault();

        this.setState({ users: [] });

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onload = function (evt) {
            var resultText = evt.target.result;
            this.setState({
                users: this._csvToUser(resultText),
            });
        }.bind(this);

        reader.readAsText(file);
    }

    /**
     * This function take a CSV text in parameters
     * And returns an array of users
     * @param {string} csvContent
     * @return {Array} users
     */
    _csvToUser(csvContent) {
        var users = [];
        var rows = csvContent.split('\n');
        for (var i = 1; i < rows.length; i++) {
            var cells = rows[i].split(',');
            if (cells.length > 1) {
                users.push({
                    email: cells[2],
                    password: cells[3],
                    firstname: cells[0],
                    lastname: cells[1],
                    language: 'fr',
                    job: 'construction',
                    license: cells[4],
                    end_premium: parseInt(cells[5]),
                    mobile: cells[6],
                    company: cells[7],
                });
            }
        }

        return users;
    }

    createUsers() {
        var errored = [];
        var arrayOfId = [];
        this.setState({ saving: true });

        Tools.createUserBatch(this.state.users)
            .then((users) => {
                users.forEach(function (user) {
                    if (user.id && !user.error) {
                        arrayOfId.push({ id: user.id });
                    } else if (user.error) {
                        errored.push(user.email);
                    }
                });

                if (arrayOfId.length > 0) {
                    return Tools.createDemoProjectBatch(arrayOfId, this.state.selectedDemoProject);
                } else {
                    return null;
                }
            })
            .then(() => {
                this.setState({ saving: false, insertedUsers: arrayOfId });

                if (arrayOfId.length > 0) {
                    this._notificationSystem.addNotification({
                        message: `Users & Demo projects were created with success for ${arrayOfId.length} users !`,
                        level: 'success',
                    });
                }

                if (errored.length > 0) {
                    this._notificationSystem.addNotification({
                        message: `Users creation failed for ${errored.length} users.`,
                        level: 'error',
                    });
                }
            })
            .catch((err) => {
                this.setState({ saving: false });

                this._notificationSystem.addNotification({
                    message: `Error while generating users and demo projects :/ ( ${errored.length} errors.`,
                    level: 'error',
                });
            });
    }

    render() {
        var listUsers = this.state.users.map((user, index) => {
            var date = new Date(user.end_premium).toISOString();
            return (
                <tr key={user.email}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>{user.password}</td>
                    <td>{user.license}</td>
                    <td>{date}</td>
                    <td>{user.mobile}</td>
                    <td>{user.company}</td>
                </tr>
            );
        });

        var listOfinsertedUsers = this.state.insertedUsers.map((user, index) => {
            return (
                <tr key={user.id}>
                    <td>{user.id}</td>
                </tr>
            );
        });

        return (
            <div>
                <Row>
                    <Col>
                        <p>
                            The goal of this tool is to help you generate a batch of users from a CSV file. The CSV
                            should be correctly formatted. If not, the tool will not work as expected. You can download
                            a sample CSV file <a href="/files/example.csv">here</a>.
                        </p>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col sm="4">
                        <FormGroup>
                            <Label for="csvFile">Upload here your CSV file:</Label>
                            <Input
                                type="file"
                                accept=".csv"
                                name="file"
                                id="csvFile"
                                onChange={(e) => this._handleFileChange(e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col sm="4">
                        <FormGroup>
                            <Label for="csvFile">Select a demo project to generate:</Label>
                            <Input
                                type="select"
                                value={this.state.selectedDemoProject}
                                onChange={(e) => this.setState({ selectedDemoProject: e.target.value })}
                            >
                                <option value="construction_fr" selected="selected">
                                    Construction FR
                                </option>
                                <option value="construction_en">Construction EN</option>
                                <option value="construction_es">Construction ES</option>
                                <option value="retail_fr">Retail FR</option>
                                <option value="retail_en">Retail EN</option>
                                <option value="retail_es">Retail ES</option>
                                <option value="energy_fr">Energy FR</option>
                                <option value="energy_en">Energy EN</option>
                                <option value="energy_es">Energy ES</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p>{this.state.users.length} users selected</p>

                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Firstname</th>
                                    <th>Lastname</th>
                                    <th>Email</th>
                                    <th>Password</th>
                                    <th>License</th>
                                    <th>End Premium (Calculated)</th>
                                    <th>Mobile</th>
                                    <th>Company</th>
                                </tr>
                            </thead>
                            <tbody>{listUsers}</tbody>
                        </Table>
                    </Col>
                </Row>

                <Row>
                    <Col sm={{ size: 2, offset: 10 }}>
                        <FormGroup>
                            <Button color="primary" onClick={this.createUsers} block>
                                {this.state.saving ? (
                                    <img src={loaderSvg} alt="loader svg" className="loaderSvg" />
                                ) : (
                                    <span>Create</span>
                                )}
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>

                {this.state.insertedUsers.length > 0 && (
                    <Row>
                        <Col sm="12">
                            <p>Successfully inserted users:</p>

                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                    </tr>
                                </thead>
                                <tbody>{listOfinsertedUsers}</tbody>
                            </Table>
                        </Col>
                    </Row>
                )}

                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default UserBatchComponent;

import React, { Component } from 'react';

import JSConfetti from 'js-confetti';

import { Row, Col, Button } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import Tools from '../api/Tools';

const jsConfetti = new JSConfetti();

class BatchProjectUserComponent extends Component {
    async cleanRedisUserCache() {
        await Tools.cleanRedisUserCache();
        jsConfetti.addConfetti();
    }
    async cleanRedisFileReadSasCache() {
        await Tools.cleanRedisFileReadSasCache();
        jsConfetti.addConfetti();
    }
    render() {
        return (
            <div>
                <h2 style={{ marginTop: '10px', marginBottom: '10px' }}>This panel is ONLY for the tech team.</h2>
                <p>
                    This panel is doing actions that are touching the infrastructure, not the users. Please use only if
                    you know what you are doing.
                </p>

                <hr />

                <Row>
                    <Col>
                        <p>This button will clean all user cache stored in Redis. Please use with great care.</p>

                        <div>
                            <Button onClick={this.cleanRedisUserCache}>Clean Redis User Cache</Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>This button will clean all file read sas cache stored in Redis. Please use with great care.</p>

                        <div>
                            <Button onClick={this.cleanRedisFileReadSasCache}>Clean Redis File Read Sas Cache</Button>
                        </div>
                    </Col>
                </Row>

                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default BatchProjectUserComponent;

export default {
    kFormatter(num) {
        return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
    },

    calculateVariation(thisPeriod, lastPeriod) {
        var thisPeriodStats;
        var lastPeriodStats;

        if (thisPeriod.hasOwnProperty('general_stats')) {
            thisPeriodStats = thisPeriod.general_stats;
            lastPeriodStats = lastPeriod.general_stats;
        } else {
            thisPeriodStats = thisPeriod;
            lastPeriodStats = lastPeriod;
        }

        var blockVariation = this.variation(thisPeriodStats.blocks_count, lastPeriodStats.blocks_count);
        var noteVariation = this.variation(thisPeriodStats.notes_count, lastPeriodStats.notes_count);
        var userVariation = this.variation(thisPeriodStats.active_users_count, lastPeriodStats.active_users_count);
        var projectVariation = this.variation(thisPeriodStats.projects_count, lastPeriodStats.projects_count);
        var reportVariation = this.variation(thisPeriodStats.reports_count, lastPeriodStats.reports_count);

        var assignmentBlockVariation = this.variation(
            thisPeriodStats.assignment_blocks_count,
            lastPeriodStats.assignment_blocks_count,
        );
        var pictureBlockVariation = this.variation(
            thisPeriodStats.picture_blocks_count,
            lastPeriodStats.picture_blocks_count,
        );
        var textBlockVariation = this.variation(thisPeriodStats.text_blocks_count, lastPeriodStats.text_blocks_count);
        var fileBlockVariation = this.variation(thisPeriodStats.file_blocks_count, lastPeriodStats.file_blocks_count);
        var planBlockVariation = this.variation(thisPeriodStats.plan_blocks_count, lastPeriodStats.plan_blocks_count);
        var priorityBlockVariation = this.variation(
            thisPeriodStats.priority_blocks_count,
            lastPeriodStats.priority_blocks_count,
        );
        var dateBlockVariation = this.variation(thisPeriodStats.date_blocks_count, lastPeriodStats.date_blocks_count);
        var formBlockVariation = this.variation(thisPeriodStats.form_blocks_count, lastPeriodStats.form_blocks_count);
        var positionBlockVariation = this.variation(
            thisPeriodStats.position_blocks_count,
            lastPeriodStats.position_blocks_count,
        );

        var tagNoteVariation = this.variation(thisPeriodStats.tag_notes_count, lastPeriodStats.tag_notes_count);

        return {
            blockVariation,
            noteVariation,
            userVariation,
            assignmentBlockVariation,
            pictureBlockVariation,
            textBlockVariation,
            projectVariation,
            reportVariation,
            fileBlockVariation,
            planBlockVariation,
            priorityBlockVariation,
            dateBlockVariation,
            formBlockVariation,
            positionBlockVariation,
        };
    },

    variation(newNumber, oldNumber) {
        if (oldNumber === 0) {
            return 'Infinity';
        } else {
            return Math.round(((newNumber - oldNumber) / oldNumber) * 100);
        }
    },

    timeDifference(current, previous) {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + ' seconds ago';
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + ' minutes ago';
        } else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + ' hours ago';
        } else if (elapsed < msPerMonth) {
            return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
        } else if (elapsed < msPerYear) {
            return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
        } else {
            return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
        }
    },
};

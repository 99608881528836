import React, { Component } from 'react';
import CompanyRowComponent from './CompanyRowComponent.js';
import UserRowComponent from './UserRowComponent.js';
import utils from '../utils/utils.js';

function variation(newNumber, oldNumber) {
    if (oldNumber === 0) {
        return 'Infinity';
    } else {
        return ((newNumber - oldNumber) / oldNumber) * 100;
    }
}

class LeaderBoard extends Component {
    render() {
        var companiesList = this.props.companies.map((company, index) => {
            var variations = utils.calculateVariation(company.this_week, company.last_week);
            company.blockVariation = variations.blockVariation;
            company.noteVariation = variations.noteVariation;
            company.projectVariation = variations.projectVariation;
            company.reportVariation = variations.reportVariation;
            return <CompanyRowComponent key={company.item.id} stat={company} index={index} />;
        });

        var usersList = this.props.users.map((user, index) => {
            return <UserRowComponent key={user.item.id} stat={user} index={index} />;
        });

        return (
            <div className="element-wrapper">
                <h6 className="element-header">{this.props.companyTitle}</h6>

                <div className="">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="padded-lg">
                                <div className="projects-list">{companiesList}</div>
                            </div>
                        </div>
                        <div className="col-lg-5 b-l-lg">
                            <div className="padded-lg">
                                <div className="element-wrapper">
                                    <div className="element-box">
                                        <div className="padded m-b">
                                            <div className="centered-header">
                                                <h6>{this.props.userTitle}</h6>
                                            </div>

                                            <div className="row">
                                                <div className="users-list-w">{usersList}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeaderBoard;

import React, { Component } from 'react';

import Chart from 'chart.js';

var fontFamily =
    '"Proxima Nova W01", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
// set defaults
Chart.defaults.global.defaultFontFamily = fontFamily;
Chart.defaults.global.tooltips.titleFontSize = 14;
Chart.defaults.global.tooltips.titleMarginBottom = 4;
Chart.defaults.global.tooltips.displayColors = false;
Chart.defaults.global.tooltips.bodyFontSize = 12;
Chart.defaults.global.tooltips.xPadding = 10;
Chart.defaults.global.tooltips.yPadding = 8;
var DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

class BlueChartComponent extends Component {
    componentDidMount() {
        var blocks = this.props.blocks;

        var labels = [];
        var data = [];
        var max = 50;

        blocks.forEach(function (block) {
            var formattedDate = new Date(block.date).toLocaleDateString('en-US', DATE_OPTIONS);
            labels.push(formattedDate);
            data.push(block.blocks_count);
            if (block.blocks_count > max) {
                max = block.blocks_count;
            }
        });

        max *= 1.1;

        var lineChart = document.getElementById('lineChart');

        // line chart data
        var lineData = {
            labels: labels,
            datasets: [
                {
                    label: 'Blocks created',
                    fill: false,
                    lineTension: 0,
                    backgroundColor: '#fff',
                    borderColor: '#6896f9',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: '#2a2f37',
                    pointBorderWidth: 3,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: '#FC2055',
                    pointHoverBorderColor: '#fff',
                    pointHoverBorderWidth: 3,
                    pointRadius: 6,
                    pointHitRadius: 10,
                    data: data,
                    spanGaps: false,
                },
            ],
        };

        // line chart init
        var myLineChart = new Chart(lineChart, {
            type: 'line',
            data: lineData,
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: '11',
                                fontColor: '#969da5',
                            },
                            gridLines: {
                                color: 'rgba(0,0,0,0.05)',
                                zeroLineColor: 'rgba(0,0,0,0.05)',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                beginAtZero: true,
                                max: max,
                            },
                        },
                    ],
                },
            },
        });

        console.log('Displaying chart');
    }

    render() {
        return <canvas height="150px" id="lineChart" width="600px"></canvas>;
    }
}

export default BlueChartComponent;

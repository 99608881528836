import React, { Component } from 'react';
import update from 'immutability-helper';
import OptionsCheckBox from './OptionsCheckBox';
import { Row, Col, Button, Input, FormGroup } from 'reactstrap';

class ReportBlockElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: props.block,
        };
        this.state.block.content = this.state.block.content || '';
        this.state.block.options = this.state.block.options || {};
        this.state.newImageUrl = '';
        if (this.state.block.type == 'text') this.state.block.options.images = this.state.block.options.images || [];
        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    handleChange(field, event) {
        const newState = update(this.state, {
            block: { [field]: { $set: event.target.value } },
        });

        this.setState(newState);

        // updating parent state
        this.props.updateValue(newState.block);
    }

    handleImageChange(index, event) {
        const newState = update(this.state, {
            block: { options: { images: { [index]: { $set: event.target.value } } } },
        });

        this.setState(newState);

        // updating parent state
        this.props.updateValue(newState.block);
    }

    handleNewImageChange(event) {
        const newState = update(this.state, {
            newImageUrl: { $set: event.target.value },
        });

        this.setState(newState);
    }

    addImageUrl() {
        if (!this.valideUrl(this.state.newImageUrl)) {
            this.props.notificationSystem.addNotification({
                message: `Invalid URL. Must start with https://.`,
                level: 'error',
            });

            return;
        }

        const newState = update(this.state, {
            block: { options: { images: { $push: [this.state.newImageUrl] } } },
            newImageUrl: { $set: '' },
        });

        this.setState(newState);

        // updating parent state
        this.props.updateValue(newState.block);
    }

    deleteImageUrl(index) {
        const newState = update(this.state, {
            block: { options: { images: { $splice: [[index, 1]] } } },
        });

        this.setState(newState);

        // updating parent state
        this.props.updateValue(newState.block);
    }

    handleOptionChange(field, newValue) {
        const newState = update(this.state, {
            block: { options: { [field]: { $set: newValue } } },
        });

        this.setState(newState);

        // updating parent state
        this.props.updateValue(newState.block);
    }

    valideUrl(str) {
        return str.startsWith('https://');
    }

    render() {
        var listImages;

        if (this.state.block.type == 'text') {
            listImages = this.state.block.options.images.map((image, index) => {
                return (
                    <Row key={index}>
                        <Col>
                            <input
                                id="contentField"
                                type="text"
                                value={image}
                                onChange={(event) => this.handleImageChange(index, event)}
                            />
                        </Col>
                        <Col>
                            <Button outline onClick={() => this.deleteImageUrl(index)}>
                                Delete
                            </Button>
                        </Col>
                    </Row>
                );
            });
        }

        return (
            <div>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <label htmlFor="positionField">Position</label>
                            <Input
                                type="text"
                                placeholder="Position"
                                value={this.state.block.position}
                                onChange={(event) => this.handleChange('position', event)}
                                id="positionField"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <label htmlFor="titleField">Title</label>
                            <Input
                                type="text"
                                placeholder="Title"
                                value={this.state.block.title}
                                onChange={(event) => this.handleChange('title', event)}
                                id="titleField"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <label htmlFor="typeField">Type</label>
                            <Input
                                type="select"
                                id="typeField"
                                value={this.state.block.type}
                                onChange={(event) => this.handleChange('type', event)}
                            >
                                <option value="header">header</option>
                                <option value="project-img">project-img</option>
                                <option value="text">text</option>
                                <option value="project-user-list">project-user-list</option>
                                <option value="zone-list">zone-list</option>
                                <option value="note-list-exhaustive">note-list-exhaustive</option>
                                <option value="note-list-synthetic">note-list-synthetic</option>
                                <option value="note-list-simple">note-list-simple</option>
                                <option value="note-pictures-only">note-pictures-only</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <label>Action</label>
                            <br />
                            <Button
                                outline
                                color="danger"
                                onClick={() => this.props.deleteBlock(this.props.index, this.props.block.id)}
                            >
                                Delete
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>

                {this.state.block.type.startsWith('note-list') && (
                    <OptionsCheckBox
                        handleOptionChange={this.handleOptionChange}
                        options={this.state.block.options || {}}
                        type={this.state.block.type}
                    />
                )}

                {this.state.block.type == 'text' && (
                    <Row>
                        <Col>
                            <FormGroup>
                                <label htmlFor="contentField">Content</label>
                                <Input
                                    type="textarea"
                                    id="contentField"
                                    value={this.state.block.content}
                                    onChange={(event) => this.handleChange('content', event)}
                                ></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                {listImages}
                {this.state.block.type == 'text' && (
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    id="contentField"
                                    type="text"
                                    placeholder="URL of the image"
                                    value={this.state.newImageUrl}
                                    onChange={(event) => this.handleNewImageChange(event)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Button outline onClick={() => this.addImageUrl()}>
                                    Add image
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

export default ReportBlockElement;

import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import update from 'immutability-helper';
import {
    Container,
    Row,
    Col,
    Label,
    Input,
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Alert,
} from 'reactstrap';
import TwoFactor from '../api/TwoFactor';
import QRCode from 'qrcode';

class SettingsComponents extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.setupTwoFactor = this.setupTwoFactor.bind(this);
        this.verifyTwoFactor = this.verifyTwoFactor.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            modal: false,
            wrongTwoFactorCode: false,
            twoFactorVerifyCode: '',
            twoFactorEnabled: null,
        };
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        TwoFactor.isTwoFactorEnabled().then((enabled) => {
            this.setState({ twoFactorEnabled: enabled });
        });
    }

    setupTwoFactor() {
        TwoFactor.setupTwoFactor().then((data) => {
            QRCode.toDataURL(data.otpauth_url, (err, data_url) => {
                this.setState({ qrcodeUrl: data_url });
                this.toggle();
            });
        });
    }

    verifyTwoFactor() {
        TwoFactor.verifyTwoFactor(this.state.twoFactorVerifyCode)
            .then((data) => {
                this.setState({ twoFactorEnabled: true });
                this.toggle();
            })
            .catch((err) => {
                this.setState({ wrongTwoFactorCode: true });
            });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        if (this.state.twoFactorEnabled == null) return <div></div>;

        return (
            <Container>
                <div style={{ marginTop: '20px' }}>
                    <h2>Settings</h2>
                    <p>
                        To ensure your account remain secured, please enable two factor authentication on your account.
                    </p>
                    <p>
                        You need to download a two factor app, like Authy for{' '}
                        <a href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=fr">Android</a> or{' '}
                        <a href="https://itunes.apple.com/fr/app/authy/id494168017?mt=8">iOS</a>.
                    </p>
                    {this.state.twoFactorEnabled === false && (
                        <p>
                            <Button onClick={this.setupTwoFactor}>Enable Two Factor</Button>
                        </p>
                    )}
                    {this.state.twoFactorEnabled === true && (
                        <p>
                            <Alert color="success">
                                Two Factor is enabled on your account. If you lost your phone, please contact the tech
                                team 🤟
                            </Alert>
                        </p>
                    )}
                    <p></p>
                </div>

                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Setup Two Factor Authentication</ModalHeader>
                    <ModalBody>
                        {this.state.wrongTwoFactorCode && <Alert color="danger">Wrong 2FA Code !</Alert>}
                        <p>Flash this code on your 2FA app :</p>
                        <p>
                            <img src={this.state.qrcodeUrl} />
                        </p>
                        <FormGroup>
                            <Label for="exampleDatetime">Enter a 2FA code :</Label>
                            <Input
                                type="text"
                                placeholder="Two Factor 6 digits code"
                                value={this.state.twoFactorVerifyCode}
                                onChange={(event) =>
                                    this.setState({
                                        twoFactorVerifyCode: event.target.value,
                                        wrongTwoFactorCode: false,
                                    })
                                }
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>
                            Cancel
                        </Button>{' '}
                        <Button color="primary" onClick={this.verifyTwoFactor}>
                            Enable 2FA
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

export default SettingsComponents;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class UserRowComponent extends Component {
    render() {
        return (
            <div className="user-w with-status status-green">
                <div className="user-avatar-w">
                    <div className="user-avatar">
                        <img alt="" src={this.props.stat.item.file_url} />
                    </div>
                </div>
                <div className="user-name">
                    <h6 className="user-title">
                        {this.props.stat.item.firstname} {this.props.stat.item.lastname}
                    </h6>
                    <div className="user-role"></div>
                </div>
                <Link to={'/user?user_id=' + this.props.stat.item.id}>
                    <div className="user-action">
                        <div className="os-icon os-icon-cv-2"></div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default UserRowComponent;

import axios from 'axios';

export default {
    create: function (reportTemplateId, reportBlock) {
        return axios
            .post(
                process.env.REACT_APP_SERVER_URL + '/admin/report_templates/' + reportTemplateId + '/report_blocks',
                reportBlock,
            )
            .then((data) => data.data);
    },

    update: function (id, reportBlock) {
        return axios
            .patch(process.env.REACT_APP_SERVER_URL + '/admin/report_blocks/' + id, reportBlock)
            .then((data) => data.data);
    },
};

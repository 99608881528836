import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import update from 'immutability-helper';
import { Container, Row, Col, Label, Input, Button, Form } from 'reactstrap';
import Stat from '../api/Stat.js';
import LeadeBoard from './LeaderBoard';
import SearchTeamComponent from './SearchTeamComponent';
import ChartComponent from './ChartComponent';
import DownloadUserListComponent from './DownloadUserListComponent';
import utils from '../utils/utils.js';

const MAX_TEAMS_IN_LIST = 20;

class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            mostActiveCompanies: [],
            mostActiveUsers: [],
            lessActiveCompanies: [],
            lessActiveUsers: [],
            allTeams: [],
            filteredTeams: [],
        };

        this.searchTeams = this.searchTeams.bind(this);
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;

        Stat.getByAudience('all', 'all', ['all_time', 'this_week', 'last_week']).then((data) => {
            var newState = {};
            data.forEach(function (elem) {
                newState[elem.interval_name] = elem;
            });

            var variation = utils.calculateVariation(newState.this_week, newState.last_week);
            newState.thisWeekGlobalVariation = variation;
            this.setState(newState);
        });

        Stat.getAudience('organization', 5, 0, 'asc').then((data) => {
            this.setState({ mostActiveCompanies: data });
        });

        Stat.getAudience('user', 7, 0, 'asc').then((data) => {
            this.setState({ mostActiveUsers: data });
        });

        Stat.getAudience('organization', 5, 0, 'desc').then((data) => {
            this.setState({ lessActiveCompanies: data });
        });

        Stat.getAudience('user', 7, 0, 'desc').then((data) => {
            this.setState({ lessActiveUsers: data });
        });

        Stat.getAudience('team', 1000, 0, 'asc').then((data) => {
            var filteredTeams = data.slice(0, MAX_TEAMS_IN_LIST);
            data.forEach(function (item, index) {
                data[index].variation = utils.calculateVariation(item.this_week, item.last_week);
            });
            this.setState({ allTeams: data, filteredTeams: filteredTeams });
        });
    }

    searchTeams(searchWord) {
        var filteredTeams = this.state.allTeams.filter(function (item) {
            var title = item.item.organization_title + ' - ' + item.item.title;
            return title.toLowerCase().search(searchWord.toLowerCase()) !== -1;
        });
        filteredTeams = filteredTeams.slice(0, MAX_TEAMS_IN_LIST);
        this.setState({ filteredTeams });
    }

    render() {
        var lastUpdatedAt;
        if (this.state.all_time) {
            lastUpdatedAt = utils.timeDifference(new Date().getTime(), this.state.all_time.generated_at);
        }

        return (
            <div>
                <div className="content-w">
                    <div className="content-i">
                        <div className="content-box pt-4">
                            {this.state.all_time && (
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="element-wrapper">
                                            <h6 className="element-header">General Stats</h6>
                                            <div className="element-box">
                                                <div className="element-info">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-8">
                                                            <div className="element-info-with-icon">
                                                                <div className="element-info-icon">
                                                                    <div className="os-icon os-icon-pie-chart-2"></div>
                                                                </div>
                                                                <div className="element-info-text">
                                                                    <h5 className="element-inner-header">
                                                                        BulldozAIR Usage
                                                                    </h5>
                                                                    <div className="element-inner-desc">
                                                                        Global Statistics about BulldozAIR usage
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2 offset-sm-2">
                                                            <div className="element-inner-desc">
                                                                Last refreshed: {lastUpdatedAt}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-xl-4">
                                                        <div className="row">
                                                            <div className="col-sm-6 b-r b-b">
                                                                <div className="el-tablo centered padded">
                                                                    <div className="value">
                                                                        {utils.kFormatter(
                                                                            this.state.all_time.general_stats
                                                                                .notes_count,
                                                                        )}
                                                                    </div>
                                                                    <div className="label">Notes</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 b-b">
                                                                <div className="el-tablo centered padded">
                                                                    <div className="value">
                                                                        {utils.kFormatter(
                                                                            this.state.all_time.general_stats
                                                                                .projects_count,
                                                                        )}
                                                                    </div>
                                                                    <div className="label">Projects</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6 b-r">
                                                                <div className="el-tablo centered padded">
                                                                    <div className="value">
                                                                        {utils.kFormatter(
                                                                            this.state.all_time.general_stats
                                                                                .active_users_count,
                                                                        )}
                                                                    </div>
                                                                    <div className="label">Active Licences</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="el-tablo centered padded">
                                                                    <div className="value">
                                                                        {utils.kFormatter(
                                                                            this.state.all_time.general_stats
                                                                                .blocks_count,
                                                                        )}
                                                                    </div>
                                                                    <div className="label">Blocks</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-xl-4">
                                                        <div className="padded b-l b-r">
                                                            <div className="element-info-with-icon smaller">
                                                                <div className="element-info-icon">
                                                                    <div className="os-icon os-icon-bar-chart-stats-up"></div>
                                                                </div>
                                                                <div className="element-info-text">
                                                                    <h5 className="element-inner-header">
                                                                        Weekly Evolution
                                                                    </h5>
                                                                    <div className="element-inner-desc">
                                                                        One week window
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="os-progress-bar primary">
                                                                <div className="bar-labels">
                                                                    <div className="bar-label-left">
                                                                        <span>Blocks created</span>
                                                                        <span
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .blockVariation >= 0
                                                                                    ? 'positive'
                                                                                    : 'negative'
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            <i
                                                                                className={
                                                                                    this.state.thisWeekGlobalVariation
                                                                                        .blockVariation >= 0
                                                                                        ? 'os-icon os-icon-arrow-2-up'
                                                                                        : 'os-icon os-icon-arrow-2-down'
                                                                                }
                                                                            ></i>{' '}
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .blockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <div className="bar-label-right">
                                                                        <span className="info">
                                                                            (
                                                                            {utils.kFormatter(
                                                                                this.state.this_week.general_stats
                                                                                    .blocks_count,
                                                                            )}{' '}
                                                                            new)
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="os-progress-bar primary">
                                                                <div className="bar-labels">
                                                                    <div className="bar-label-left">
                                                                        <span>Notes created</span>
                                                                        <span
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .noteVariation >= 0
                                                                                    ? 'positive'
                                                                                    : 'negative'
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            <i
                                                                                className={
                                                                                    this.state.thisWeekGlobalVariation
                                                                                        .noteVariation >= 0
                                                                                        ? 'os-icon os-icon-arrow-2-up'
                                                                                        : 'os-icon os-icon-arrow-2-down'
                                                                                }
                                                                            ></i>{' '}
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .noteVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <div className="bar-label-right">
                                                                        <span className="info">
                                                                            (
                                                                            {utils.kFormatter(
                                                                                this.state.this_week.general_stats
                                                                                    .notes_count,
                                                                            )}{' '}
                                                                            new)
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="os-progress-bar primary">
                                                                <div className="bar-labels">
                                                                    <div className="bar-label-left">
                                                                        <span>Users signed up</span>
                                                                        <span
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .userVariation >= 0
                                                                                    ? 'positive'
                                                                                    : 'negative'
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            <i
                                                                                className={
                                                                                    this.state.thisWeekGlobalVariation
                                                                                        .userVariation >= 0
                                                                                        ? 'os-icon os-icon-arrow-2-up'
                                                                                        : 'os-icon os-icon-arrow-2-down'
                                                                                }
                                                                            ></i>{' '}
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .userVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <div className="bar-label-right">
                                                                        <span className="info">
                                                                            (
                                                                            {utils.kFormatter(
                                                                                this.state.this_week.general_stats
                                                                                    .active_users_count,
                                                                            )}{' '}
                                                                            new)
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="hidden-lg-down col-xl-4">
                                                        <div className="padded">
                                                            <div className="el-tablo bigger">
                                                                <div className="value">
                                                                    {utils.kFormatter(
                                                                        this.state.this_week.general_stats.blocks_count,
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className={
                                                                        this.state.thisWeekGlobalVariation
                                                                            .blockVariation >= 0
                                                                            ? 'trending trending-up'
                                                                            : 'trending trending-down'
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            this.state.thisWeekGlobalVariation
                                                                                .blockVariation
                                                                        }
                                                                        %
                                                                    </span>
                                                                    <i
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .blockVariation >= 0
                                                                                ? 'os-icon os-icon-arrow-up2'
                                                                                : 'os-icon os-icon-arrow-down2'
                                                                        }
                                                                    ></i>
                                                                </div>
                                                                <div className="label">Blocks created</div>
                                                            </div>
                                                            <div className="el-chart-w">
                                                                <ChartComponent blocks={this.state.this_week.blocks} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <LeadeBoard
                                companyTitle="Most Active Companies (weekly)"
                                userTitle="Our Power Users"
                                companies={this.state.mostActiveCompanies}
                                users={this.state.mostActiveUsers}
                            />

                            <LeadeBoard
                                companyTitle="Less Active Companies (weekly)"
                                userTitle="Our Less Active Users"
                                companies={this.state.lessActiveCompanies}
                                users={this.state.lessActiveUsers}
                            />

                            <SearchTeamComponent companies={this.state.filteredTeams} search={this.searchTeams} />

                            <DownloadUserListComponent />
                        </div>
                    </div>

                    <div className="display-type"></div>
                </div>
                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default DashboardComponent;

import React, { Component } from 'react';
import update from 'immutability-helper';
import ReportBlockList from './ReportBlockList';
import ReportTemplate from '../api/ReportTemplate';
import ReportUserList from './ReportUserList';
import { Row, Col, Input, Form, Button, FormGroup } from 'reactstrap';

class ReportTemplateEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            template: this.cleanNullField(props.template),
        };
        this.saveTemplate = this.saveTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    cleanNullField(template) {
        if (!template) return template;

        template.title = template.title || '';
        template.type = template.type || '';
        template.style_file = template.style_file || '';
        template.file_type = template.file_type || '';
        return template;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            template: this.cleanNullField(nextProps.template),
        });
    }

    render() {
        if (!this.state.template) return <div></div>;

        return (
            <div>
                <h3>{this.state.template.title}</h3>
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label htmlFor="titleField">Title</label>
                                <Input
                                    type="text"
                                    value={this.state.template.title}
                                    onChange={(event) => this.handleChange('title', event.target.value)}
                                    placeholder="Title"
                                    id="titleField"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <label htmlFor="typeField">Type</label>
                                <Input
                                    type="select"
                                    id="typeField"
                                    onChange={(event) => this.handleChange('file_type', event.target.value)}
                                    value={this.state.template.file_type}
                                >
                                    <option value="pdf">pdf</option>
                                    <option value="docx">docx</option>
                                    <option value="csv">csv</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label htmlFor="languageField">Language</label>
                                <Input
                                    type="text"
                                    value={this.state.template.language}
                                    onChange={(event) => this.handleChange('language', event.target.value)}
                                    placeholder="Language"
                                    id="languageField"
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <label htmlFor="styleFileField">Style file</label>

                                {this.state.template.file_type == 'docx' && (
                                    <Input
                                        type="select"
                                        id="typeField"
                                        value={this.state.template.style_file}
                                        onChange={(event) => this.handleChange('style_file', event.target.value)}
                                    >
                                        <option value="">-------</option>
                                        <option value="reference-exhaustif.docx">
                                            reference-exhaustif.docx ( Portrait )
                                        </option>
                                    </Input>
                                )}

                                {this.state.template.file_type == 'csv' && (
                                    <Input
                                        type="select"
                                        id="typeField"
                                        value={this.state.template.style_file}
                                        onChange={(event) => this.handleChange('style_file', event.target.value)}
                                    >
                                        <option value="">-------</option>
                                        <option value="multiline-observation">Multiline observation CSV</option>
                                    </Input>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 2, offset: 10 }}>
                            <FormGroup>
                                <Button outline color="success" onClick={this.saveTemplate} block>
                                    {this.state.saving ? (
                                        <img src="img/three-dots.svg" className="loaderSvg" />
                                    ) : (
                                        <span>Save Template</span>
                                    )}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>

                <hr />

                <ReportBlockList
                    blocks={this.props.template.report_blocks}
                    notificationSystem={this.props.notificationSystem}
                    template={this.props.template}
                />

                <hr />

                <ReportUserList
                    users={this.props.template.users}
                    template={this.props.template}
                    notificationSystem={this.props.notificationSystem}
                />
            </div>
        );
    }

    handleChange(field, value) {
        const newState = update(this.state, {
            template: { [field]: { $set: value } },
        });

        this.setState(newState);
    }

    saveTemplate(e) {
        //e.preventDefault();
        console.log('Saving template');
        this.setState({ saving: true });
        ReportTemplate.update(this.props.template.id, this.state.template)
            .then((newTemplate) => {
                this.setState({ saving: false, template: newTemplate });
                this.props.notificationSystem.addNotification({
                    message: 'Template saved with success',
                    level: 'success',
                });
            })
            .catch(() => {
                this.setState({ saving: false });
                this.props.notificationSystem.addNotification({
                    message: 'Failed to save template',
                    level: 'error',
                });
            });
    }
}

export default ReportTemplateEditor;

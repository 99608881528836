import React, { Component } from 'react';
import update from 'immutability-helper';
import Team from '../api/Team.js';
import FormTemplate from '../api/FormTemplate.js';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import UserSearchBar from '../../user/components/UserSearchBar.js';

class FormTemplateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formTemplates: [],
            currentUserFormTemplates: [],
            currentUserEmail: '',
        };
        this.getFormTemplatesByUser = this.getFormTemplatesByUser.bind(this);
        this.addFormTemplate = this.addFormTemplate.bind(this);
        this.deleteFormTemplate = this.deleteFormTemplate.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
    }

    componentDidMount() {
        FormTemplate.getByTeam(this.props.team.id).then((formTemplates) => this.setState({ formTemplates }));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.team.id !== nextProps.team.id) {
            FormTemplate.getByTeam(nextProps.team.id).then((formTemplates) => this.setState({ formTemplates }));
        }
    }

    getFormTemplatesByUser(email) {
        FormTemplate.getByUser(email).then((formTemplates) =>
            this.setState({ currentUserFormTemplates: formTemplates }),
        );
    }

    addFormTemplate(index, formTemplate) {
        FormTemplate.addToTeam(formTemplate.id, this.props.team.id)
            .then(() => {
                const newState = update(this.state, {
                    formTemplates: { $push: [formTemplate] },
                });

                this.setState(newState);
            })
            .catch(() => {
                this.props.notificationSystem.addNotification({
                    message: `Fail to add formTemplate "${formTemplate.title}" ... Maybe the formTemplate is already assigned to the team !`,
                    level: 'error',
                });
            });
    }

    deleteFormTemplate(index, formTemplate) {
        FormTemplate.removeFromTeam(formTemplate.id, this.props.team.id).then(() => {
            const newState = update(this.state, {
                formTemplates: { $splice: [[index, 1]] },
            });

            this.setState(newState);
        });
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.getFormTemplatesByUser();
        }
    }

    render() {
        var listFormTemplates = this.state.formTemplates.map((formTemplate, index) => {
            return (
                <tr key={formTemplate.id}>
                    <td>{formTemplate.title}</td>
                    <td>
                        <Button outline color="danger" onClick={() => this.deleteFormTemplate(index, formTemplate)}>
                            Delete
                        </Button>
                    </td>
                </tr>
            );
        });

        var listFormTemplatesUser = this.state.currentUserFormTemplates.map((formTemplate, index) => {
            return (
                <tr key={formTemplate.id}>
                    <td>{formTemplate.title}</td>
                    <td>
                        <Button outline color="success" onClick={() => this.addFormTemplate(index, formTemplate)}>
                            Add
                        </Button>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <Row>
                    <Col md="6">
                        <h4>Current form templates assigned</h4>
                        <Row>
                            <Col>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{listFormTemplates}</tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>

                    <Col md="6">
                        <p>
                            You need to search for a user by his email, and then you can add his form templates to this
                            team.
                        </p>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <UserSearchBar
                                        take="4"
                                        userChanged={(user) => this.getFormTemplatesByUser(user.email)}
                                        userCleared={() => this.setState({ currentUserFormTemplates: [] })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{listFormTemplatesUser}</tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default FormTemplateComponent;

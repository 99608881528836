/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Col, Input, Label } from 'reactstrap';

class NewBillingGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isOpen === false && state.title !== '') {
            return {
                title: ''
            };
        }
        // Return null to indicate no change to state.
        return null;
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>Create a new Billing Group</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label for="billingGroupName" sm={2}>
                                    Name
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="billingGroupName"
                                        placeholder="Name"
                                        value={this.state.title}
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.props.createBillingGroup(this.props.organization.id, this.state.title)}
                        >
                            Create
                        </Button>{' '}
                        <Button color="secondary" onClick={this.props.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default NewBillingGroupModal;

import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import update from 'immutability-helper';
import { Container, Row, Col, Label, Input, Button, Form } from 'reactstrap';
import ReportTemplate from '../api/ReportTemplate';
import ReportTemplateEditor from './ReportTemplateEditor.js';
import ReportTemplateSelect from './ReportTemplateSelect';

class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            reportTemplates: [],
            currentTemplate: null,
            selectedTemplateId: null,
            newReportName: '',
        };
        this.selectReportTemplate = this.selectReportTemplate.bind(this);
        this.createReport = this.createReport.bind(this);
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        ReportTemplate.get().then((reportTemplates) => this.setState({ reportTemplates }));
    }

    render() {
        return (
            <Container>
                <Row className="mt-4">
                    <Col md="6">
                        <ReportTemplateSelect
                            selectedTemplateId={this.state.selectedTemplateId}
                            reportTemplates={this.state.reportTemplates}
                            selectReportTemplate={this.selectReportTemplate}
                        />
                    </Col>
                    <Col>
                        <Label>Create a report</Label>
                        <Input
                            type="text"
                            placeholder="Name"
                            value={this.state.newReportName}
                            onChange={(event) => this.setState({ newReportName: event.target.value })}
                        />
                    </Col>
                    <Col md="2">
                        <Label>Action</Label>
                        <br />
                        <Button onClick={this.createReport}>Create</Button>
                    </Col>
                </Row>

                <hr />

                <ReportTemplateEditor
                    template={this.state.currentTemplate}
                    notificationSystem={this._notificationSystem}
                />

                <NotificationSystem ref="notificationSystem" />
            </Container>
        );
    }

    selectReportTemplate(reportTemplateId) {
        if (reportTemplateId == 'null') return this.setState({ currentTemplate: null, selectedTemplateId: null });

        ReportTemplate.getById(reportTemplateId).then((reportTemplate) => {
            this.setState({ currentTemplate: reportTemplate, selectedTemplateId: reportTemplate.id });
        });
    }

    createReport() {
        return ReportTemplate.create({ title: this.state.newReportName, language: 'fr', file_type: 'docx' }).then(
            (newTemplate) => {
                newTemplate.report_blocks = [];
                newTemplate.users = [];

                const newState = update(this.state, {
                    reportTemplates: { $push: [newTemplate] },
                    newReportName: { $set: '' },
                    currentTemplate: { $set: newTemplate },
                    selectedTemplateId: { $set: newTemplate.id },
                });

                this.setState(newState);
            },
        );
    }
}

export default DashboardComponent;

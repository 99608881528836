import React, { Component } from 'react';
import ReportBlockElement from './ReportBlockElement.js';
import ReportBlock from '../api/ReportBlock';
import { Button } from 'reactstrap';

class ReportBlockList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            blocks: props.blocks,
        };
        this.toUpdate = {};
        this.updateValue = this.updateValue.bind(this);
        this.saveAllBlocks = this.saveAllBlocks.bind(this);
        this.addNewBlock = this.addNewBlock.bind(this);
        this.deleteBlock = this.deleteBlock.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            blocks: nextProps.blocks,
        });
    }

    updateValue(block) {
        this.toUpdate[block.id] = block;
    }

    saveAllBlocks() {
        this.setState({ saving: true });
        var promises = [];
        for (var id in this.toUpdate) {
            promises.push(ReportBlock.update(id, this.toUpdate[id]));
        }

        return Promise.all(promises)
            .then(() => {
                console.log('Updated');
                this.toUpdate = {};
                this.toInsert = [];
                this.setState({ saving: false });
                this.props.notificationSystem.addNotification({
                    message: 'Blocks saved with success',
                    level: 'success',
                });
            })
            .catch((err) => {
                this.setState({ saving: false });
                var message = 'Problem while saving block. ';
                if (err.response.data && err.response.data.meta && err.response.data.meta.error_message) {
                    err.response.data.meta.error_message.forEach((error) => {
                        message += error.field + ' : ' + error.message;
                    });
                }
                this.props.notificationSystem.addNotification({
                    message: message,
                    level: 'error',
                });
            });
    }

    addNewBlock() {
        var newBlock = {
            type: 'text',
        };
        if (this.state.blocks.length) {
            newBlock.position = this.state.blocks[this.state.blocks.length - 1].position + 1;
        } else {
            newBlock.position = 1;
        }
        newBlock.title = 'Block ' + newBlock.position;

        ReportBlock.create(this.props.template.id, newBlock).then((block) => {
            this.setState({
                blocks: this.state.blocks.concat([block]),
            });
        });
    }

    deleteBlock(index, id) {
        ReportBlock.update(id, { is_deleted: true }).then((block) => {
            var newArrayBlocks = this.state.blocks.slice();
            newArrayBlocks.splice(index, 1);
            this.setState({ blocks: newArrayBlocks });
        });
    }

    render() {
        var listBlocks = this.state.blocks.map((block, index) => {
            return (
                <ReportBlockElement
                    key={block.id}
                    block={block}
                    index={index}
                    deleteBlock={this.deleteBlock}
                    updateValue={this.updateValue}
                    notificationSystem={this.props.notificationSystem}
                />
            );
        });

        return (
            <div>
                <h4>Block list</h4>
                {listBlocks}

                <Button color="primary" outline onClick={this.addNewBlock}>
                    Add new block
                </Button>
                <div className="float-right">
                    <Button color="success" outline onClick={this.saveAllBlocks}>
                        {this.state.saving ? (
                            <img src="img/three-dots.svg" className="loaderSvg" />
                        ) : (
                            <span>Save all blocks</span>
                        )}
                    </Button>
                </div>
            </div>
        );
    }
}

export default ReportBlockList;

import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';

class BillingGroupSelect extends Component {
    constructor(props) {
        super(props);
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }

    onChangeSelect(option) {
        if (option && option.value) {
            this.props.selectBillingGroup(option.billingGroup, option.index);
        } else {
            this.props.selectBillingGroup(null, null);
        }
    }

    render() {
        let selectedOption = null;
        const selectOptions = this.props.billingGroups.map((billingGroup, index) => {
            const option = {
                label: billingGroup.title,
                value: billingGroup.id,
                index,
                billingGroup,
            };
            if (billingGroup.id === this.props.selectedBillingGroupId) {
                selectedOption = option;
            }
            return option;
        });

        return (
            <FormGroup>
                <label htmlFor="billingGroupSelect">Billing Group:</label>
                <Select options={selectOptions} value={selectedOption} onChange={this.onChangeSelect} />
            </FormGroup>
        );
    }
}

export default BillingGroupSelect;

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Col,
    Input,
    Label,
    Row,
    Spinner,
} from 'reactstrap';
import update from 'immutability-helper';
import { Async } from 'react-select';
import Organization from '../api/Organization';
import 'react-select/dist/react-select.css';
import User from '../../user/api/User';
import Promise from 'bluebird';

import ColorPicker from './ColorPicker';

class EditOrganizationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.organization.title,
            theme: props.organization.theme,
            max_nb_seat_creators: props.organization.max_nb_seat_creators,
            max_nb_seat_commenters: props.organization.max_nb_seat_commenters,
            max_nb_seat_readers: props.organization.max_nb_seat_readers,
            project_license_auto_provisioning: props.organization.project_license_auto_provisioning,
            admins: [],
            loading: true,
            isDeletingOrganization: false,
        };
        this.squareImageToUpload = null;
        this.rectangularImageToUpload = null;
        this.getOptions = this.getOptions.bind(this);
        this.handleChangeSelectAdmin = this.handleChangeSelectAdmin.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleSquareFileChange = this.handleSquareFileChange.bind(this);
        this.handleRectangularFileChange = this.handleRectangularFileChange.bind(this);
        this.saveOrganization = this.saveOrganization.bind(this);
    }

    handleColorChange(property, color) {
        const originalState = Object.assign({}, this.state);
        if (!originalState.theme) {
            originalState.theme = {};
        }
        const newState = update(originalState, {
            theme: {
                [property]: {
                    $set: color,
                },
            },
        });
        this.setState(newState);
    }

    getFileSize(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                const image = new Image();
                image.onload = () => {
                    resolve({
                        width: image.width,
                        height: image.height,
                    });
                };
                image.onerror = reject;
                image.src = fileReader.result;
            };
            fileReader.onerror = reject;
            fileReader.readAsDataURL(file);
        });
    }

    async handleSquareFileChange(event) {
        const file = event.target.files[0];
        if (file.type !== 'image/png') {
            this.setState({ squareImageError: true });
            return;
        }
        const { width, height } = await this.getFileSize(file);
        const squareImageError = width !== 62 || height !== 62;
        this.setState({ squareImageError });
        if (!squareImageError) {
            this.squareImageToUpload = {
                file,
                width,
                height,
            };
            this.setState({
                newSquareFile: URL.createObjectURL(file),
            });
        }
    }

    async handleRectangularFileChange(event) {
        const file = event.target.files[0];
        if (file.type !== 'image/png') {
            this.setState({ rectangularImageError: true });
            return;
        }
        const { width, height } = await this.getFileSize(file);
        const rectangularImageError = width !== 300 || height !== 90;
        this.setState({ rectangularImageError });
        if (!rectangularImageError) {
            this.rectangularImageToUpload = {
                file,
                width,
                height,
            };
            this.setState({
                newRectangularFile: URL.createObjectURL(file),
            });
        }
    }

    getImageVersionNumber(currentImageUrl) {
        let version = 0;
        if (currentImageUrl) {
            const filename = currentImageUrl.substring(currentImageUrl.lastIndexOf('/') + 1);
            const fileNameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
            const fileSplitted = fileNameWithoutExtension.split('_');
            if (fileSplitted.length === 4) {
                const currentVersion = parseInt(fileSplitted[3], 10);
                if (Number.isInteger(currentVersion)) {
                    version = currentVersion + 1;
                }
            }
        }
        return version;
    }

    async saveOrganization() {
        // don't save if errored
        if (this.state.rectangularImageError || this.state.squareImageError) {
            return null;
        }
        try {
            await this.setState({ loading: true, saveFormError: null });
            if (this.state.max_nb_seat_creators < 0) {
                throw new Error('Max nb seat creators should be a positive number');
            }
            if (this.state.max_nb_seat_commenters < 0) {
                throw new Error('Max nb seat commenters should be a positive number');
            }
            if (this.state.max_nb_seat_readers < 0) {
                throw new Error('Max nb seat readers should be a positive number');
            }
            const toUpdate = {
                title: this.state.title,
                max_nb_seat_creators: this.state.max_nb_seat_creators,
                max_nb_seat_commenters: this.state.max_nb_seat_commenters,
                max_nb_seat_readers: this.state.max_nb_seat_readers,
                project_license_auto_provisioning: this.state.project_license_auto_provisioning,
            };
            const theme = this.state.theme;
            // if we need to upload a square image
            if (this.squareImageToUpload) {
                const version = this.getImageVersionNumber(theme ? theme.square_logo_url : null);
                const sas = await Organization.getSasImage(
                    this.props.organization.id,
                    this.squareImageToUpload.width,
                    this.squareImageToUpload.height,
                    version,
                );
                await Organization.uploadImage(sas.sas, this.squareImageToUpload.file);
                this.squareImageToUpload = null;
                if (theme) {
                    theme.square_logo_url = sas.sas.substring(0, sas.sas.indexOf('?'));
                }
            }
            // if we need to upload a rectangular image
            if (this.rectangularImageToUpload) {
                const version = this.getImageVersionNumber(theme ? theme.rectangular_logo_url : null);
                const sas = await Organization.getSasImage(
                    this.props.organization.id,
                    this.rectangularImageToUpload.width,
                    this.rectangularImageToUpload.height,
                    version,
                );
                await Organization.uploadImage(sas.sas, this.rectangularImageToUpload.file);
                this.rectangularImageToUpload = null;
                if (theme) {
                    theme.rectangular_logo_url = sas.sas.substring(0, sas.sas.indexOf('?'));
                }
            }

            if (
                theme &&
                theme.font_color &&
                theme.secondary_font_color &&
                theme.primary_color &&
                theme.secondary_color &&
                theme.square_logo_url &&
                theme.rectangular_logo_url
            ) {
                toUpdate.theme = theme;
            } else if (theme) {
                this.setState({ themeNotCompleteError: true });
                throw new Error('THEME_NOT_COMPLETE');
            } else if (theme === null) {
                toUpdate.theme = null;
            }

            this.setState({ themeNotCompleteError: false });

            await this.props.updateOrganization(this.props.organization.id, toUpdate);

            await Promise.delay(500);

            this.setState({
                saveSuccess: true,
                newSquareFile: null,
                newRectangularFile: null,
                loading: false,
            });
        } catch (e) {
            console.log(e);
            const errorMessage = e && e.message ? e.message : 'An unknown error occured';
            this.setState({
                saveFormError: errorMessage,
                saveSuccess: false,
                loading: false,
            });
        }
    }

    toggleConfirmDeleteOrga() {
        this.setState({
            isDeletingOrganization: !this.state.isDeletingOrganization,
        });
    }

    async componentWillReceiveProps(nextProps) {
        const isOpenChanged = this.props.isOpen !== nextProps.isOpen;
        if (nextProps.isOpen === true && isOpenChanged) {
            await this.setState({ loading: true });
            const admins = await Organization.getAdmins(nextProps.organization.id);
            var adminsFormatted = admins.map((admin) => {
                return { label: admin.email, value: admin.id };
            });
            this.squareImageToUpload = null;
            this.rectangularImageToUpload = null;
            this.setState({
                loading: false,
                admins: adminsFormatted,
                title: nextProps.organization.title,
                theme: nextProps.organization.theme,
                max_nb_seat_creators: nextProps.organization.max_nb_seat_creators,
                max_nb_seat_commenters: nextProps.organization.max_nb_seat_commenters,
                max_nb_seat_readers: nextProps.organization.max_nb_seat_readers,
                project_license_auto_provisioning: nextProps.organization.project_license_auto_provisioning,
                newSquareFile: null,
                newRectangularFile: null,
                themeNotCompleteError: false,
                squareImageError: false,
                rectangularImageError: false,
            });
        }
    }

    getOptions(input) {
        if (!input || input.length === 0) return Promise.resolve({ options: [], complete: true });

        return User.search(input, 5).then((users) => {
            var options = [];
            users.forEach((user) => options.push({ label: user.email, value: user.id }));
            return { options, complete: true };
        });
    }

    handleChangeSelectAdmin(selectedValueOrValues) {
        var { toAdd, toRemove } = this.diff(this.state.admins, selectedValueOrValues);
        var promises = [];
        toAdd.forEach((admin) => promises.push(Organization.addAdmin(this.props.organization.id, admin.value)));
        toRemove.forEach((admin) => promises.push(Organization.deleteAdmin(this.props.organization.id, admin.value)));

        return Promise.all(promises).then(() => this.setState({ admins: selectedValueOrValues }));
    }

    // return the difference between two array
    // element added + element removed
    // useful to compare here admin added / removed
    diff(oldList, newList) {
        var toAdd = [];
        var toRemove = [];

        newList.forEach((elemNew) => {
            if (oldList.find((elemOld) => elemOld.value === elemNew.value) === undefined) {
                toAdd.push(elemNew);
            }
        });

        oldList.forEach((elemOld) => {
            if (newList.find((elemNew) => elemOld.value === elemNew.value) === undefined) {
                toRemove.push(elemOld);
            }
        });

        return { toAdd, toRemove };
    }

    render() {
        const squareImageFile = this.state.newSquareFile || (this.state.theme && this.state.theme.square_logo_url);
        const rectangularImageFile =
            this.state.newRectangularFile || (this.state.theme && this.state.theme.rectangular_logo_url);
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>Edit Organization</ModalHeader>
                    <ModalBody>
                        {!this.state.loading && (
                            <Form>
                                {this.state.themeNotCompleteError && (
                                    <Alert color="danger">Theme is not complete. Every fields must be specified.</Alert>
                                )}

                                {this.state.saveFormError && <Alert color="danger">{this.state.saveFormError}</Alert>}

                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="orgaName">Name</Label>
                                            <Input
                                                type="text"
                                                name="title"
                                                id="orgaName"
                                                placeholder="Name"
                                                value={this.state.title}
                                                onChange={(e) => this.setState({ title: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label for="orgaName">Max internals</Label>
                                            <Input
                                                type="number"
                                                name="max_nb_seat_creators"
                                                id="max_nb_seat_creators"
                                                placeholder="Nb seats internals"
                                                value={this.state.max_nb_seat_creators}
                                                min="0"
                                                onChange={(e) =>
                                                    this.setState({ max_nb_seat_creators: e.target.value })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label for="orgaName">Max subcontractors</Label>
                                            <Input
                                                type="number"
                                                name="max_nb_seat_commenters"
                                                id="max_nb_seat_commenters"
                                                placeholder="Nb seats subcontractor"
                                                value={this.state.max_nb_seat_commenters}
                                                min="0"
                                                onChange={(e) =>
                                                    this.setState({ max_nb_seat_commenters: e.target.value })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label for="orgaName">Max observators</Label>
                                            <Input
                                                type="number"
                                                name="max_nb_seat_readers"
                                                id="max_nb_seat_readers"
                                                placeholder="Nb seats observators"
                                                value={this.state.max_nb_seat_readers}
                                                min="0"
                                                onChange={(e) => this.setState({ max_nb_seat_readers: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="orgaName">Primary Color</Label>
                                            <ColorPicker
                                                color={this.state.theme && this.state.theme.primary_color}
                                                onColorChange={(color) =>
                                                    this.handleColorChange('primary_color', color)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="orgaName">Secondary Color</Label>
                                            <ColorPicker
                                                color={this.state.theme && this.state.theme.secondary_color}
                                                onColorChange={(color) =>
                                                    this.handleColorChange('secondary_color', color)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="orgaName">Font Color</Label>
                                            <ColorPicker
                                                color={this.state.theme && this.state.theme.font_color}
                                                onColorChange={(color) => this.handleColorChange('font_color', color)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="orgaName">Secondary Font Color</Label>
                                            <ColorPicker
                                                color={this.state.theme && this.state.theme.secondary_font_color}
                                                onColorChange={(color) =>
                                                    this.handleColorChange('secondary_font_color', color)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="orgaName">Square logo (62x62)</Label>
                                            {squareImageFile && (
                                                <div>
                                                    <img
                                                        src={squareImageFile}
                                                        alt="Square"
                                                        style={{ height: '62px' }}
                                                    />
                                                </div>
                                            )}
                                            {this.state.squareImageError && (
                                                <Alert color="danger">Invalid image: Should be a png, 62x62.</Alert>
                                            )}
                                            <input
                                                type="file"
                                                name="file"
                                                className="form-control"
                                                onChange={this.handleSquareFileChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="orgaName">Rectangular Logo (300x90)</Label>
                                            {rectangularImageFile && (
                                                <div>
                                                    <img
                                                        src={rectangularImageFile}
                                                        alt="Square"
                                                        style={{ height: '62px' }}
                                                    />
                                                </div>
                                            )}
                                            {this.state.rectangularImageError && (
                                                <Alert color="danger">Invalid image: Should be a png, 300x90.</Alert>
                                            )}
                                            <input
                                                type="file"
                                                name="file"
                                                className="form-control"
                                                onChange={this.handleRectangularFileChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Button
                                        color="primary"
                                        outline
                                        size="sm"
                                        block
                                        onClick={() => this.setState({ theme: null })}
                                    >
                                        Reset theme
                                    </Button>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="orgaName">Admins</Label>
                                    <Async
                                        name="form-field-name"
                                        value={this.state.admins}
                                        multi={true}
                                        onChange={this.handleChangeSelectAdmin}
                                        loadOptions={this.getOptions}
                                    />
                                </FormGroup>

                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="orgaName">License auto-provisioning</Label>
                                            <p className="small">
                                                When inviting another user in a project in this organization, the
                                                invited user will automatically be added to a billing group.
                                            </p>
                                            <Input
                                                type="select"
                                                id="MailNotification"
                                                value={this.state.project_license_auto_provisioning ? 'yes' : 'no'}
                                                onChange={(e) =>
                                                    this.setState({
                                                        project_license_auto_provisioning:
                                                            e.target.value === 'yes' ? true : false,
                                                    })
                                                }
                                            >
                                                <option value="no">No</option>
                                                <option value="yes">Yes</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        {this.state.loading && (
                            <div className="d-flex justify-content-center">
                                <Spinner
                                    style={{
                                        width: '3rem',
                                        height: '3rem',
                                        marginTop: '2rem',
                                        marginBottom: '2rem',
                                    }}
                                />
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter className="d-flex">
                        {!this.state.isDeletingOrganization && (
                            <Button
                                color="danger"
                                className="mr-auto"
                                disabled={this.state.loading}
                                onClick={() => this.toggleConfirmDeleteOrga()}
                            >
                                Delete this organization
                            </Button>
                        )}{' '}
                        {this.state.isDeletingOrganization && (
                            <div className="mr-auto">
                                <Button
                                    color="secondary"
                                    className="mr-2"
                                    onClick={() => this.props.deleteOrganization(this.props.organization.id)}
                                >
                                    Confirm ?
                                </Button>
                                <Button color="danger" onClick={() => this.toggleConfirmDeleteOrga()}>
                                    Cancel
                                </Button>
                            </div>
                        )}{' '}
                        <Button color="primary" onClick={this.saveOrganization} disabled={this.state.loading}>
                            Save
                        </Button>{' '}
                        <Button color="secondary" onClick={this.props.toggle} disabled={this.state.loading}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default EditOrganizationModal;

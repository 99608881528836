import React, { Component } from 'react';
import update from 'immutability-helper';
import { Row, Col, Input, FormGroup } from 'reactstrap';

class OptionsCheckBox extends Component {
    constructor(props) {
        super(props);
        props.options.enable_column_number = props.options.enable_column_number || false;
        props.options.enable_column_picture = props.options.enable_column_picture || false;
        props.options.enable_column_title = props.options.enable_column_title || false;
        props.options.enable_column_observation = props.options.enable_column_observation || false;
        props.options.enable_column_note_status = props.options.enable_column_note_status || false;
        props.options.enable_column_creator = props.options.enable_column_creator || false;
        props.options.enable_column_attribution = props.options.enable_column_attribution || false;
        props.options.enable_column_contributors = props.options.enable_column_contributors || false;
        props.options.enable_column_tags = props.options.enable_column_tags || false;
        props.options.enable_column_zone = props.options.enable_column_zone || false;
        props.options.enable_column_file_block = props.options.enable_column_file_block || false;
        props.options.enable_column_priority_block = props.options.enable_column_priority_block || false;
        props.options.enable_column_created_at = props.options.enable_column_created_at || false;
        props.options.enable_column_updated_at = props.options.enable_column_updated_at || false;
        props.options.enable_column_spotted_on = props.options.enable_column_spotted_on || false;
        props.options.enable_column_due_date = props.options.enable_column_due_date || false;
        props.options.enable_column_spotted_on = props.options.enable_column_spotted_on || false;
        props.options.enable_column_done_on = props.options.enable_column_done_on || false;
        props.options.enable_zone_picture = props.options.enable_zone_picture || false;
        props.options.enable_column_position_block = props.options.enable_column_position_block || false;
        props.options.include_forms = props.options.include_forms || false;
        props.options.sort_by = props.options.sort_by || '';

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(field, value) {
        this.props.handleOptionChange(field, value);
    }

    render() {
        return (
            <div>
                {this.props.type != 'note-list-exhaustive' && (
                    <div>
                        <Row>
                            <Col>
                                <input
                                    type="checkbox"
                                    checked={this.props.options.enable_column_number}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_number',
                                            !this.props.options.enable_column_number,
                                        )
                                    }
                                />
                                <label className="label-inline">Number</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    checked={this.props.options.enable_column_picture}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_picture',
                                            !this.props.options.enable_column_picture,
                                        )
                                    }
                                />
                                <label className="label-inline">Picture</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_title"
                                    checked={this.props.options.enable_column_title}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_title',
                                            !this.props.options.enable_column_title,
                                        )
                                    }
                                />
                                <label className="label-inline">Title</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_observation"
                                    checked={this.props.options.enable_column_observation}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_observation',
                                            !this.props.options.enable_column_observation,
                                        )
                                    }
                                />
                                <label className="label-inline">Observation</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_note_status"
                                    checked={this.props.options.enable_column_note_status}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_note_status',
                                            !this.props.options.enable_column_note_status,
                                        )
                                    }
                                />
                                <label className="label-inline">Note Status</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_creator"
                                    checked={this.props.options.enable_column_creator}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_creator',
                                            !this.props.options.enable_column_creator,
                                        )
                                    }
                                />
                                <label className="label-inline">Creator</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_attribution"
                                    checked={this.props.options.enable_column_attribution}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_attribution',
                                            !this.props.options.enable_column_attribution,
                                        )
                                    }
                                />
                                <label className="label-inline">Attribution</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_contributors"
                                    checked={this.props.options.enable_column_contributors}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_contributors',
                                            !this.props.options.enable_column_contributors,
                                        )
                                    }
                                />
                                <label className="label-inline">Contributors</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_tags"
                                    checked={this.props.options.enable_column_tags}
                                    onChange={(event) =>
                                        this.handleChange('enable_column_tags', !this.props.options.enable_column_tags)
                                    }
                                />
                                <label className="label-inline">Tags</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_zone"
                                    checked={this.props.options.enable_column_zone}
                                    onChange={(event) =>
                                        this.handleChange('enable_column_zone', !this.props.options.enable_column_zone)
                                    }
                                />
                                <label className="label-inline">Zone</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_file_block"
                                    checked={this.props.options.enable_column_file_block}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_file_block',
                                            !this.props.options.enable_column_file_block,
                                        )
                                    }
                                />
                                <label className="label-inline">File blocks</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_priority_block"
                                    checked={this.props.options.enable_column_priority_block}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_priority_block',
                                            !this.props.options.enable_column_priority_block,
                                        )
                                    }
                                />
                                <label className="label-inline">Priority Block</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_created_at"
                                    checked={this.props.options.enable_column_created_at}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_created_at',
                                            !this.props.options.enable_column_created_at,
                                        )
                                    }
                                />
                                <label className="label-inline">Created At</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_updated_at"
                                    checked={this.props.options.enable_column_updated_at}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_updated_at',
                                            !this.props.options.enable_column_updated_at,
                                        )
                                    }
                                />
                                <label className="label-inline">Updated At</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_spotted_on"
                                    checked={this.props.options.enable_column_spotted_on}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_spotted_on',
                                            !this.props.options.enable_column_spotted_on,
                                        )
                                    }
                                />
                                <label className="label-inline">Spotted On</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_due_date"
                                    checked={this.props.options.enable_column_due_date}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_due_date',
                                            !this.props.options.enable_column_due_date,
                                        )
                                    }
                                />
                                <label className="label-inline">Due Date</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_done_on"
                                    checked={this.props.options.enable_column_done_on}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_done_on',
                                            !this.props.options.enable_column_done_on,
                                        )
                                    }
                                />
                                <label className="label-inline">Done On</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_zone_picture"
                                    checked={this.props.options.enable_zone_picture}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_zone_picture',
                                            !this.props.options.enable_zone_picture,
                                        )
                                    }
                                />
                                <label className="label-inline">Zone Picture</label>
                            </Col>
                            <Col>
                                <input
                                    type="checkbox"
                                    id="enable_column_position_block"
                                    checked={this.props.options.enable_column_position_block}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_position_block',
                                            !this.props.options.enable_column_position_block,
                                        )
                                    }
                                />
                                <label className="label-inline">Position Block</label>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                    </div>
                )}

                {this.props.type == 'note-list-exhaustive' && (
                    <div>
                        <Row>
                            <Col>
                                <input
                                    type="checkbox"
                                    checked={this.props.options.enable_column_picture}
                                    onChange={(event) =>
                                        this.handleChange(
                                            'enable_column_picture',
                                            !this.props.options.enable_column_picture,
                                        )
                                    }
                                />
                                <label className="label-inline">Picture</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input
                                    type="checkbox"
                                    checked={this.props.options.include_forms}
                                    onChange={(event) =>
                                        this.handleChange('include_forms', !this.props.options.include_forms)
                                    }
                                />
                                <label className="label-inline">Include Forms</label>
                            </Col>
                        </Row>
                    </div>
                )}

                <Row>
                    <Col md="4">
                        <FormGroup>
                            <label htmlFor="sortBy">Sort By</label>
                            <Input
                                type="select"
                                value={this.props.options.sort_by}
                                onChange={(event) => this.handleChange('sort_by', event.target.value)}
                            >
                                <option value="">-------</option>
                                <option value="zones">Zones</option>
                                <option value="participants">Participants</option>
                                <option value="tags">Tags</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    {this.props.type == 'note-list-exhaustive' && (
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="sortBy">Order Blocks dir</label>
                                <Input
                                    type="select"
                                    value={this.props.options.sort_blocks_dir}
                                    onChange={(event) => this.handleChange('sort_blocks_dir', event.target.value)}
                                >
                                    <option value="">-------</option>
                                    <option value="desc">Descending (3..2..1)</option>
                                    <option value="asc">Ascending (1..2..3)</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

export default OptionsCheckBox;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Stat from '../api/Stat.js';
import Csv from '../api/Csv';

const headers = {
    id: 'id',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    organization_title: 'Organization Title',
    team_title: 'Team title',
    license: 'license',
    end_premium: 'End Premium',
    created_at: 'Created At',
};

var headersOrder = [
    'organization_title',
    'team_title',
    'email',
    'id',
    'firstname',
    'lastname',
    'license',
    'end_premium',
    'created_at',
];

class DownloadUserListComponent extends Component {
    getListOfUsers() {
        Stat.getUsers().then((users) => {
            Csv.exportCSVFile(headers, headersOrder, users, 'bulldozair-users');
        });
    }

    render() {
        return (
            <div className="element-wrapper">
                <h6 className="element-header">Users lists</h6>

                <div className="element-box">
                    <div class="row">
                        <div class="col-md-4">To download a list of users in organization, just click here</div>
                        <div class="col-md-2">
                            <button className="btn btn-sm btn-secondary" onClick={this.getListOfUsers}>
                                Download CSV
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DownloadUserListComponent;

import React, { Component } from 'react';

class CompanyRowComponent extends Component {
    render() {
        return (
            <div className="project-box">
                <div className="project-head">
                    <div className="project-title">
                        <h5>
                            {this.props.index + 1}. {this.props.stat.item.title}
                        </h5>
                    </div>
                    <div className="project-users">
                        <div className="more">{this.props.stat.active_users_count} users</div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-3">
                                    <div className="el-tablo highlight">
                                        <div className="label">Blocks</div>
                                        <div className="value">{this.props.stat.this_week.blocks_count}</div>
                                        <div
                                            className={
                                                this.props.stat.blockVariation >= 0
                                                    ? 'trending trending-up-basic'
                                                    : 'trending trending-down-basic'
                                            }
                                        >
                                            <span>{this.props.stat.blockVariation}%</span>
                                            <i
                                                className={
                                                    this.props.stat.blockVariation >= 0
                                                        ? 'os-icon os-icon-arrow-2-up'
                                                        : 'os-icon os-icon-arrow-2-down'
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="el-tablo highlight">
                                        <div className="label">Notes</div>
                                        <div className="value">{this.props.stat.this_week.notes_count}</div>
                                        <div
                                            className={
                                                this.props.stat.noteVariation >= 0
                                                    ? 'trending trending-up-basic'
                                                    : 'trending trending-down-basic'
                                            }
                                        >
                                            <span>{this.props.stat.noteVariation}%</span>
                                            <i
                                                className={
                                                    this.props.stat.noteVariation >= 0
                                                        ? 'os-icon os-icon-arrow-2-up'
                                                        : 'os-icon os-icon-arrow-2-down'
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="el-tablo highlight">
                                        <div className="label">Projects</div>
                                        <div className="value">{this.props.stat.this_week.projects_count}</div>
                                        <div
                                            className={
                                                this.props.stat.projectVariation >= 0
                                                    ? 'trending trending-up-basic'
                                                    : 'trending trending-down-basic'
                                            }
                                        >
                                            <span>{this.props.stat.projectVariation}%</span>
                                            <i
                                                className={
                                                    this.props.stat.projectVariation >= 0
                                                        ? 'os-icon os-icon-arrow-2-up'
                                                        : 'os-icon os-icon-arrow-2-down'
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="el-tablo highlight">
                                        <div className="label">Reports</div>
                                        <div className="value">{this.props.stat.this_week.reports_count}</div>
                                        <div
                                            className={
                                                this.props.stat.reportVariation >= 0
                                                    ? 'trending trending-up-basic'
                                                    : 'trending trending-down-basic'
                                            }
                                        >
                                            <span>{this.props.stat.reportVariation}%</span>
                                            <i
                                                className={
                                                    this.props.stat.reportVariation >= 0
                                                        ? 'os-icon os-icon-arrow-2-up'
                                                        : 'os-icon os-icon-arrow-2-down'
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyRowComponent;

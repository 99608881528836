import React, { Component } from 'react';
import update from 'immutability-helper';
import Team from '../api/Team.js';
import ContactBook from '../api/ContactBook.js';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import UserSearchBar from '../../user/components/UserSearchBar.js';

class TeamContactBookComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactBooks: [],
            currentUserContactBooks: [],
            currentUserEmail: '',
        };
        this.getContactBooksByUser = this.getContactBooksByUser.bind(this);
        this.addContactBook = this.addContactBook.bind(this);
        this.deleteContactBook = this.deleteContactBook.bind(this);
    }

    componentDidMount() {
        ContactBook.getByTeam(this.props.team.id).then((contactBooks) => this.setState({ contactBooks }));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.team.id !== nextProps.team.id) {
            ContactBook.getByTeam(nextProps.team.id).then((contactBooks) => this.setState({ contactBooks }));
        }
    }

    getContactBooksByUser(email) {
        ContactBook.getByUser(email).then((contactBooks) => this.setState({ currentUserContactBooks: contactBooks }));
    }

    addContactBook(index, contactBook) {
        ContactBook.addToTeam(contactBook.id, this.props.team.id)
            .then(() => {
                const newState = update(this.state, {
                    contactBooks: { $push: [contactBook] },
                });

                this.setState(newState);
            })
            .catch(() => {
                this.props.notificationSystem.addNotification({
                    message: `Fail to add contactBook "${contactBook.title}" ... Maybe the Contact Book is already assigned to the team !`,
                    level: 'error',
                });
            });
    }

    deleteContactBook(index, contactBook) {
        ContactBook.removeFromTeam(contactBook.id, this.props.team.id).then(() => {
            const newState = update(this.state, {
                contactBooks: { $splice: [[index, 1]] },
            });

            this.setState(newState);
        });
    }

    render() {
        var listContactBooks = this.state.contactBooks.map((contactBook, index) => {
            return (
                <tr key={contactBook.id}>
                    <td>{contactBook.title}</td>
                    <td>
                        <Button outline color="danger" onClick={() => this.deleteContactBook(index, contactBook)}>
                            Delete
                        </Button>
                    </td>
                </tr>
            );
        });

        var listContactBooksUser = this.state.currentUserContactBooks.map((contactBook, index) => {
            return (
                <tr key={contactBook.id}>
                    <td>{contactBook.title}</td>
                    <td>
                        <Button outline color="success" onClick={() => this.addContactBook(index, contactBook)}>
                            Add
                        </Button>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <Row>
                    <Col md="6">
                        <h4>Current Contact Books assigned</h4>
                        <Row>
                            <Col>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{listContactBooks}</tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>

                    <Col md="6">
                        <p>
                            You need to search for a user by his email, and then you can add his contact books to this
                            team.
                        </p>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <UserSearchBar
                                        take="4"
                                        userChanged={(user) => this.getContactBooksByUser(user.email)}
                                        userCleared={() => this.setState({ currentUserContactBooks: [] })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{listContactBooksUser}</tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default TeamContactBookComponent;

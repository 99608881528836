/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Col, Input, Label } from 'reactstrap';

class NewOrganizationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
        };
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>Create a new Organization</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label for="orgaName" sm={2}>
                                    Name
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="orgaName"
                                        placeholder="Name"
                                        value={this.state.value}
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.props.createOrganization(this.state.title)}>
                            Create
                        </Button>{' '}
                        <Button color="secondary" onClick={this.props.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default NewOrganizationModal;

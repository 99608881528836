import React, { Component } from 'react';

import Organization from '../api/Organization';
import Team from '../api/Team';
import BillingGroup from '../api/BillingGroup';
import { Container, Row, Col, ButtonGroup, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import OrganizationSelect from './OrganizationSelect.js';
import NewOrganizationModal from './NewOrganizationModal';
import EditOrganizationModal from './EditOrganizationModal';
import NewTeamModal from './NewTeamModal';
import NewBillingGroupModal from './NewBillingGroupModal';
import EditTeamModal from './EditTeamModal';
import EditBillingGroupModal from './EditBillingGroupModal';
import TeamSelect from './TeamSelect';
import BillingGroupSelect from './BillingGroupSelect';
import FormTemplateComponent from './FormTemplate';
import ContactBookComponent from './TeamContactBook';
import TeamUserList from './TeamUserList';
import TeamFeature from './TeamFeature';
import TeamPremium from './TeamPremium';
import update from 'immutability-helper';
import classnames from 'classnames';

class OrganizationComponent extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {
            organizations: [],
            teams: null,
            newOrganizationName: '',
            currentOrganization: null,
            selectedOrganizationId: null,
            selectedOrganizationIndex: null,
            currentTeam: null,
            selectedTeamId: null,
            selectedTeamIndex: null,
            newOrganizationModalOpen: false,
            editOrganizationModalOpen: false,
            newTeamModalOpen: false,
            editTeamModalOpen: false,
            users: [],
            activeTab: '1',
        };

        this.createOrganization = this.createOrganization.bind(this);
        this.updateOrganization = this.updateOrganization.bind(this);
        this.selectOrganization = this.selectOrganization.bind(this);
        this.deleteOrganization = this.deleteOrganization.bind(this);
        this.openModal = this.openModal.bind(this);
        this.selectTeam = this.selectTeam.bind(this);
        this.selectBillingGroup = this.selectBillingGroup.bind(this);
        this.addFeature = this.addFeature.bind(this);
        this.removeFeature = this.removeFeature.bind(this);
        this.createTeam = this.createTeam.bind(this);
        this.deleteTeam = this.deleteTeam.bind(this);
        this.createBillingGroup = this.createBillingGroup.bind(this);
        this.updateBillingGroup = this.updateBillingGroup.bind(this);
        this.deleteBillingGroup = this.deleteBillingGroup.bind(this);
        this.updateTeam = this.updateTeam.bind(this);
        this.toggle = this.toggle.bind(this);
        this.replaceUsers = this.replaceUsers.bind(this);
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        Organization.get().then((organizations) => this.setState({ organizations }));
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    shouldDisplayTeams() {
        return this.props && this.props.location && this.props.location.pathname !== '/billing_group';
    }

    render() {
        return (
            <Container>
                <Row className="mt-4">
                    <Col md="4">
                        <OrganizationSelect
                            selectedOrganizationId={this.state.selectedOrganizationId}
                            organizations={this.state.organizations}
                            selectOrganization={this.selectOrganization}
                        />
                    </Col>
                    <Col md="2">
                        <label>Action</label>
                        <br />
                        <ButtonGroup>
                            <Button onClick={() => this.setState({ newOrganizationModalOpen: true })}>+</Button>{' '}
                            <Button
                                disabled={this.state.currentOrganization === null}
                                onClick={() => this.setState({ editOrganizationModalOpen: true })}
                            >
                                Edit
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col md="4">
                        {this.state.currentOrganization && this.state.teams && this.shouldDisplayTeams() && (
                            <TeamSelect
                                selectedTeamId={this.state.selectedTeamId}
                                teams={this.state.teams}
                                selectTeam={this.selectTeam}
                            />
                        )}
                        {this.state.currentOrganization && this.state.billingGroups && !this.shouldDisplayTeams() && (
                            <BillingGroupSelect
                                selectedBillingGroupId={this.state.selectedBillingGroupId}
                                billingGroups={this.state.billingGroups}
                                selectBillingGroup={this.selectBillingGroup}
                            />
                        )}
                    </Col>
                    <Col md="2">
                        {this.state.currentOrganization && this.state.teams && this.shouldDisplayTeams() && (
                            <div>
                                <label>Action</label>
                                <br />
                                <ButtonGroup>
                                    <Button onClick={() => this.setState({ newTeamModalOpen: true })}>+</Button>{' '}
                                    <Button
                                        disabled={this.state.currentTeam === null}
                                        onClick={() => this.setState({ editTeamModalOpen: true })}
                                    >
                                        Edit
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                        {this.state.currentOrganization && this.state.billingGroups && !this.shouldDisplayTeams() && (
                            <div>
                                <label>Action</label>
                                <br />
                                <ButtonGroup>
                                    <Button onClick={() => this.setState({ newBillingGroupModalOpen: true })}>+</Button>{' '}
                                    <Button
                                        disabled={this.state.currentBillingGroup === null}
                                        onClick={() => this.setState({ editBillingGroupModalOpen: true })}
                                    >
                                        Edit
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                    </Col>
                </Row>

                {this.state.currentTeam && this.shouldDisplayTeams() && (
                    <div>
                        <hr />

                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeTab === '1',
                                        })}
                                        onClick={() => {
                                            this.toggle('1');
                                        }}
                                    >
                                        Users
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeTab === '2',
                                        })}
                                        onClick={() => {
                                            this.toggle('2');
                                        }}
                                    >
                                        Forms
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeTab === '3',
                                        })}
                                        onClick={() => {
                                            this.toggle('3');
                                        }}
                                    >
                                        License
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeTab === '4',
                                        })}
                                        onClick={() => {
                                            this.toggle('4');
                                        }}
                                    >
                                        Contacts
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: this.state.activeTab === '5',
                                        })}
                                        onClick={() => {
                                            this.toggle('5');
                                        }}
                                    >
                                        Features
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <TeamUserList
                                                replaceUsers={this.replaceUsers}
                                                users={this.state.users}
                                                team={this.state.currentTeam}
                                                isAllowedToEditCurrentTeam={this.state.isAllowedToEditCurrentTeam}
                                                notificationSystem={this._notificationSystem}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <FormTemplateComponent
                                                team={this.state.currentTeam}
                                                notificationSystem={this._notificationSystem}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm="12">
                                            <TeamPremium
                                                team={this.state.currentTeam}
                                                notificationSystem={this._notificationSystem}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row>
                                        <Col sm="12">
                                            <ContactBookComponent
                                                team={this.state.currentTeam}
                                                notificationSystem={this._notificationSystem}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="5">
                                    <Row>
                                        <Col sm="12">
                                            <TeamFeature
                                                features={this.state.currentTeam.features}
                                                addFeature={this.addFeature}
                                                removeFeature={this.removeFeature}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                )}

                <NewOrganizationModal
                    isOpen={this.state.newOrganizationModalOpen}
                    toggle={() =>
                        this.setState({
                            newOrganizationModalOpen: !this.state.newOrganizationModalOpen,
                        })
                    }
                    createOrganization={this.createOrganization}
                />
                {this.state.currentOrganization && (
                    <EditOrganizationModal
                        isOpen={this.state.editOrganizationModalOpen}
                        toggle={() =>
                            this.setState({
                                editOrganizationModalOpen: !this.state.editOrganizationModalOpen,
                            })
                        }
                        updateOrganization={this.updateOrganization}
                        deleteOrganization={this.deleteOrganization}
                        organization={this.state.currentOrganization}
                    />
                )}
                <NewTeamModal
                    isOpen={this.state.newTeamModalOpen}
                    toggle={() => this.setState({ newTeamModalOpen: !this.state.newTeamModalOpen })}
                    createTeam={this.createTeam}
                    organization={this.state.currentOrganization}
                />
                <NewBillingGroupModal
                    isOpen={this.state.newBillingGroupModalOpen}
                    toggle={() => this.setState({ newBillingGroupModalOpen: !this.state.newBillingGroupModalOpen })}
                    createBillingGroup={this.createBillingGroup}
                    organization={this.state.currentOrganization}
                />
                {this.state.currentTeam && (
                    <EditTeamModal
                        isOpen={this.state.editTeamModalOpen}
                        toggle={() =>
                            this.setState({
                                editTeamModalOpen: !this.state.editTeamModalOpen,
                            })
                        }
                        team={this.state.currentTeam}
                        isAllowedToEditCurrentTeam={this.state.isAllowedToEditCurrentTeam}
                        deleteTeam={this.deleteTeam}
                        updateTeam={this.updateTeam}
                    />
                )}

                {this.state.currentBillingGroup && (
                    <EditBillingGroupModal
                        isOpen={this.state.editBillingGroupModalOpen}
                        toggle={() =>
                            this.setState({
                                editBillingGroupModalOpen: !this.state.editBillingGroupModalOpen,
                            })
                        }
                        billingGroup={this.state.currentBillingGroup}
                        deleteBillingGroup={this.deleteBillingGroup}
                        updateBillingGroup={this.updateBillingGroup}
                    />
                )}

                <NotificationSystem ref="notificationSystem" />
            </Container>
        );
    }

    openModal() {
        this.setState({ newOrganizationModalOpen: true });
    }

    async selectOrganization(organization, index) {
        if (organization === null) {
            return this.setState({
                currentOrganization: null,
                selectedOrganizationId: null,
                selectedOrganizationIndex: null,
                teams: null,
                currentTeam: null,
                selectedTeamId: null,
                selectedTeamIndex: null,
                users: [],
            });
        }

        const [teams, billingGroups] = await Promise.all([
            Organization.getTeams(organization.id),
            Organization.getBillingGroups(organization.id),
        ]);

        this.setState({
            teams,
            billingGroups,
            selectedOrganizationId: organization.id,
            selectedOrganizationIndex: index,
            currentOrganization: organization,
            currentTeam: null,
            selectedTeamId: null,
            selectedTeamIndex: null,
            users: [],
        });
    }

    selectTeam(team, index) {
        if (team === null)
            return this.setState({
                currentTeam: null,
                selectedTeamId: null,
                isAllowedToEditCurrentTeam: null,
                selectedTeamIndex: null,
                users: [],
            });

        Team.getUsers(team.id).then((users) => {
            this.setState({
                users,
                currentTeam: team,
                isAllowedToEditCurrentTeam: !team.billing_group_id && !team.default_organization_team,
                selectedTeamId: team.id,
                selectedTeamIndex: index,
            });
        });
    }

    selectBillingGroup(billingGroup, index) {
        if (billingGroup === null)
            return this.setState({
                currentBillingGroup: null,
                selectedBillingGroupId: null,
                selectedBillingGroupIndex: null,
                users: [],
            });

        this.setState({
            currentBillingGroup: billingGroup,
            selectedBillingGroupId: billingGroup.id,
            selectedBillingGroupIndex: index,
        });
    }

    createOrganization(title) {
        return Organization.create({ title: title }).then((newOrganization) => {
            const newState = update(this.state, {
                organizations: { $push: [newOrganization] },
                newOrganizationName: { $set: '' },
                currentOrganization: { $set: newOrganization },
                selectedOrganizationId: { $set: newOrganization.id },
                newOrganizationModalOpen: { $set: false },
            });

            this.setState(newState);
            this.selectOrganization(newOrganization);
        });
    }

    createTeam(organizationId, title) {
        return Team.create(organizationId, { title }).then((newTeam) => {
            const newState = update(this.state, {
                teams: { $push: [newTeam] },
                newTeamModalOpen: { $set: false },
            });

            this.setState(newState);
            this.selectTeam(newTeam.id, newTeam, this.state.teams.length - 1);
        });
    }

    async createBillingGroup(organizationId, title) {
        const data = {
            title,
            max_nb_seat: 0,
            max_web_device: 1,
            max_mobile_device: 2,
            license: 'internal',
            billing_type: 'bulldozair',
        };
        const newBillingGroup = await BillingGroup.create(organizationId, data);
        const newState = update(this.state, {
            billingGroups: { $push: [newBillingGroup] },
            newBillingGroupModalOpen: { $set: false },
        });
        this.setState(newState);
        this.selectBillingGroup(newBillingGroup.id, newBillingGroup, this.state.billingGroups.length - 1);
    }

    addFeature(code) {
        var newState;
        if (this.state.currentTeam.features instanceof Array) {
            newState = update(this.state, {
                currentTeam: { features: { $push: [code] } },
                teams: {
                    [this.state.selectedTeamIndex]: {
                        features: { $push: [code] },
                    },
                },
            });
        } else {
            newState = update(this.state, {
                currentTeam: { features: { $set: [code] } },
                teams: {
                    [this.state.selectedTeamIndex]: {
                        features: { $set: [code] },
                    },
                },
            });
        }

        this.updateTeam(this.state.selectedTeamId, newState.teams[this.state.selectedTeamIndex]).then(() => {
            this.setState(newState);
        });
    }

    removeFeature(code) {
        var index = null;
        var i = 0;
        while ((index === null) & (i < this.state.currentTeam.features.length)) {
            if (this.state.currentTeam.features[i] === code) {
                index = i;
            }
            i++;
        }

        if (index !== null) {
            const newState = update(this.state, {
                currentTeam: { features: { $splice: [[index, 1]] } },
                teams: {
                    [this.state.selectedTeamIndex]: {
                        features: { $splice: [[index, 1]] },
                    },
                },
            });

            this.updateTeam(this.state.selectedTeamId, newState.teams[this.state.selectedTeamIndex]).then(() => {
                this.setState(newState);
            });
        }
    }

    updateTeam(teamId, team) {
        return Team.update(teamId, team).then((updatedTeam) => {
            const newState = update(this.state, {
                currentTeam: { $set: updatedTeam },
                teams: {
                    [this.state.selectedTeamIndex]: {
                        title: { $set: updatedTeam.title },
                    },
                },
                editTeamModalOpen: { $set: false },
            });

            this.setState(newState);
        });
    }

    async updateBillingGroup(billingGroupId, billingGroup) {
        const updatedBillingGroup = await BillingGroup.update(billingGroupId, billingGroup);
        console.log(this.state.selectedBillingGroupIndex);
        const newState = update(this.state, {
            currentBillingGroup: { $set: updatedBillingGroup },
            billingGroups: {
                [this.state.selectedBillingGroupIndex]: {
                    $set: updatedBillingGroup,
                },
            },
            editBillingGroupModalOpen: { $set: false },
        });

        this.setState(newState);
    }

    async updateOrganization(organizationId, organization) {
        const updatedOrganization = await Organization.update(organizationId, organization);
        const newState = update(this.state, {
            currentOrganization: { $set: updatedOrganization },
            organizations: {
                [this.state.selectedOrganizationIndex]: {
                    $set: updatedOrganization,
                },
            },
            editOrganizationModalOpen: { $set: false },
        });
        await this.setState(newState);
    }

    deleteTeam(teamId) {
        return Team.delete(teamId).then(() => {
            const newState = update(this.state, {
                currentTeam: { $set: null },
                selectedTeamId: { $set: null },
                selectedTeamIndex: { $set: null },
                teams: { $splice: [[this.state.selectedTeamIndex, 1]] },
                editTeamModalOpen: { $set: false },
            });

            this.setState(newState);
        });
    }

    async deleteBillingGroup(billingGroupId) {
        await BillingGroup.delete(billingGroupId);
        const newState = update(this.state, {
            currentBillingGroup: { $set: null },
            selectedBillingGroupId: { $set: null },
            selectedBillingGroupIndex: { $set: null },
            billingGroups: { $splice: [[this.state.selectedBillingGroupIndex, 1]] },
            editBillingGroupModalOpen: { $set: false },
        });

        this.setState(newState);
    }

    deleteOrganization(organizationId) {
        return Organization.delete(organizationId).then(() => {
            const newState = update(this.state, {
                currentOrganization: { $set: null },
                selectedOrganizationId: { $set: null },
                selectedOrganizationIndex: { $set: null },
                organizations: { $splice: [[this.state.selectedOrganizationIndex, 1]] },
                editOrganizationModalOpen: { $set: false },
            });

            this.setState(newState);
        });
    }

    replaceUsers(newUsers) {
        const newState = update(this.state, {
            users: { $set: newUsers },
        });

        this.setState(newState);
    }
}

export default OrganizationComponent;

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import update from 'immutability-helper';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Row,
    Col,
    Input,
    Label,
} from 'reactstrap';

const smallTextStyle = { fontSize: '12px' };

class EditBillingGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billingGroup: null,
            isDeleting: false,
        };

        this.updateBillingGroup = this.updateBillingGroup.bind(this);
        this.deleteBillingGroup = this.deleteBillingGroup.bind(this);
        this.updateLocalBillingGroup = this.updateLocalBillingGroup.bind(this);
        this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.billingGroup?.id !== state.billingGroup?.id) {
            return {
                billingGroup: props.billingGroup,
            };
        }
        // Return null to indicate no change to state.
        return null;
    }

    toggleConfirmDelete() {
        this.setState({
            isDeleting: !this.state.isDeleting,
        });
    }
    updateLocalBillingGroup(key, value) {
        const newState = update(this.state, {
            billingGroup: {
                [key]: {
                    $set: value,
                },
            },
        });
        this.setState(newState);
    }

    updateBillingGroup() {
        const newBillingGroup = {
            title: this.state.billingGroup.title,
            max_web_device: this.state.billingGroup.max_web_device,
            max_mobile_device: this.state.billingGroup.max_mobile_device,
            license: this.state.billingGroup.license,
        };
        this.props.updateBillingGroup(this.props.billingGroup.id, newBillingGroup);
    }

    deleteBillingGroup() {
        this.props.deleteBillingGroup(this.props.billingGroup.id);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>Edit Billing Group</ModalHeader>
                    <ModalBody>
                        {this.state.billingGroup && (
                            <Form>
                                <FormGroup>
                                    <Label for="orgaName">Name</Label>

                                    <Input
                                        type="text"
                                        name="title"
                                        id="orgaName"
                                        placeholder="Name"
                                        value={this.state.billingGroup.title}
                                        onChange={(e) => this.updateLocalBillingGroup('title', e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <Col>
                                        <Label for="maxWebDevices">Max number of Web devices</Label>

                                        <Input
                                            type="number"
                                            min="1"
                                            name="maxWebDevices"
                                            id="maxWebDevices"
                                            placeholder="Number of web devices"
                                            value={this.state.billingGroup.max_web_device}
                                            onChange={(e) =>
                                                this.updateLocalBillingGroup('max_web_device', e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <Label for="orgaName">Max number of Mobile devices</Label>

                                        <Input
                                            type="number"
                                            min="1"
                                            name="title"
                                            id="orgaName"
                                            placeholder="Number of mobile devices"
                                            value={this.state.billingGroup.max_mobile_device}
                                            onChange={(e) =>
                                                this.updateLocalBillingGroup('max_mobile_device', e.target.value)
                                            }
                                        />
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <p style={smallTextStyle}>
                                            <b>Note:</b> Users are not allowed to use more than a fixed amount of web
                                            and mobile devices at the same time. We do this to prevent users from
                                            abusing Bulldozair and being 10 users on one account. You can change this
                                            setting here and it'll apply to all users in this billing group.
                                        </p>
                                    </Col>
                                </Row>
                                <FormGroup row>
                                    <Col>
                                        <Label for="license">License</Label>

                                        <Input
                                            type="select"
                                            name="license"
                                            id="license"
                                            value={this.state.billingGroup.license}
                                            onChange={(e) => this.updateLocalBillingGroup('license', e.target.value)}
                                        >
                                            <option value="internal">Internal</option>
                                            <option value="subcontractor">SubContractor</option>
                                            <option value="observator">Observator</option>
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Label for="billingType">Billing type</Label>

                                        <Input
                                            type="select"
                                            name="billingType"
                                            id="billingType"
                                            value={this.state.billingGroup.billing_type}
                                            disabled
                                        >
                                            <option value="bulldozair">Bulldozair</option>
                                            <option value="orange">Orange</option>
                                            <option value="dalkia">Dalkia</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                            </Form>
                        )}
                    </ModalBody>
                    <ModalFooter className="d-flex">
                        {!this.state.isDeleting && (
                            <Button color="danger" className="mr-auto" onClick={this.toggleConfirmDelete}>
                                Delete
                            </Button>
                        )}
                        {this.state.isDeleting && (
                            <div className="mr-auto">
                                <Button color="secondary" className="mr-2" onClick={this.deleteBillingGroup}>
                                    Confirm ?
                                </Button>
                                <Button color="danger" onClick={this.toggleConfirmDelete}>
                                    Cancel
                                </Button>
                            </div>
                        )}{' '}
                        <Button color="primary" onClick={this.updateBillingGroup}>
                            Update
                        </Button>{' '}
                        <Button color="secondary" onClick={this.props.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default EditBillingGroupModal;

import React, { Component } from 'react';

import style from './style.module.css';
import { Badge, Row, Col, Button, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';

const { REACT_APP_CLIENT_ADMIN_PANEL_URL } = process.env;

class UserFormComponent extends Component {
    displayDate = (timestamp, color = 'primary') => {
        if (!timestamp) {
            return <Badge color="warning">No date</Badge>;
        }
        console.log(timestamp);
        return <Badge color={color}>{new Date(timestamp).toLocaleString('fr-FR')}</Badge>;
    };

    render() {
        var createdAt = new Date(this.props.user.created_at).toISOString().slice(0, 10);
        const isSsoUser = this.props.user.keycloak_id !== null && this.props.user.keycloak_id !== undefined;

        const forceFullSyncColorEnabled =
            this.props.user.force_full_sync_enabled_at && this.props.user.last_started_sync_started_at;

        let forceFullSyncColor = 'primary';
        if (forceFullSyncColorEnabled) {
            const forceFullSyncWasUsed =
                this.props.user.force_full_sync_enabled_at < this.props.user.last_started_sync_started_at;
            forceFullSyncColor = forceFullSyncWasUsed ? 'success' : 'danger';
        }
        const { user, loading, forceFullSyncLoading, refreshingUserDataLoading } = this.props;

        return (
            <div className="user-form mb-4">
                <div className={loading ? style.greyZoneWhileLoading : ''}>
                    <h3>User information</h3>
                    {isSsoUser && (
                        <Row>
                            <Col>
                                <p>
                                    This user is a SSO user. You cannot edit his firstname, lastname, email and
                                    password.
                                </p>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="Id">Id</Label>
                                <Input type="text" disabled id="Id" placeholder="Id" value={this.props.user.id} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="Id">Created At</Label>
                                <Input type="text" disabled id="CreatedAt" placeholder="CreatedAt" value={createdAt} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FormGroup>
                                <Label for="Email">Email</Label>
                                <Input
                                    type="text"
                                    id="Email"
                                    placeholder="Email"
                                    value={this.props.user.email}
                                    onChange={(e) => this.props.localUpdate('email', e.target.value)}
                                    disabled={isSsoUser}
                                />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label for="Password">Password</Label>
                                <Input
                                    type="password"
                                    id="Password"
                                    placeholder="Password"
                                    value={user.password || ''}
                                    onChange={(e) => this.props.localUpdate('password', e.target.value)}
                                    disabled={isSsoUser}
                                    invalid={this.props.passwordError}
                                />
                                {this.props.passwordError && (
                                    <FormFeedback>The password should have: {this.props.passwordError}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FormGroup>
                                <Label for="Firstname">Firstname</Label>
                                <Input
                                    type="text"
                                    id="Firstname"
                                    placeholder="Firstname"
                                    value={user.firstname || ''}
                                    onChange={(e) => this.props.localUpdate('firstname', e.target.value)}
                                    disabled={isSsoUser}
                                />
                            </FormGroup>
                        </Col>

                        <Col md>
                            <FormGroup>
                                <Label for="Lastname">Lastname</Label>
                                <Input
                                    type="text"
                                    id="Lastname"
                                    placeholder="Lastname"
                                    value={user.lastname || ''}
                                    onChange={(e) => this.props.localUpdate('lastname', e.target.value)}
                                    disabled={isSsoUser}
                                />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label for="Mobile">Mobile</Label>
                                <Input
                                    type="text"
                                    id="Mobile"
                                    placeholder="Mobile"
                                    value={user.mobile || ''}
                                    onChange={(e) => this.props.localUpdate('mobile', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label for="mobileVerified">Mobile verified</Label>
                                <Input
                                    type="select"
                                    id="mobileVerified"
                                    value={this.props.user.mobile_verified_at ? 'yes' : 'no'}
                                    onChange={(e) => {
                                        const value = e.target.value === 'yes' ? new Date().getTime() : null;
                                        this.props.localUpdate('mobile_verified_at', value);
                                    }}
                                >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md>
                            <FormGroup>
                                <Label for="MailNotification">Mail Notification</Label>
                                <Input
                                    type="select"
                                    id="MailNotification"
                                    value={user.mail_notification || ''}
                                    onChange={(e) => this.props.localUpdate('mail_notification', e.target.value)}
                                >
                                    <option value="0">No Notification</option>
                                    <option value="2">Daily Notification</option>
                                    <option value="1">Weekly Notification</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label for="Licence">Licence</Label>
                                <Input
                                    type="select"
                                    id="Licence"
                                    value={this.props.user.license}
                                    onChange={(e) => this.props.localUpdate('license', e.target.value)}
                                >
                                    <option value="Professional">Professional</option>
                                    <option value="Enterprise">Enterprise</option>
                                    <option value="ExtendedEnterprise">Extended enterprise</option>
                                    <option value="Trial">Trial</option>
                                    <option value="ExtendedTrial">Extended trial</option>
                                    <option value="LicenceEnded">Licence ended</option>
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col md>
                            <FormGroup>
                                <Label for="EndPremium">End Premium</Label>
                                <Input
                                    type="date"
                                    id="EndPremium"
                                    value={this.props.user.end_premium}
                                    onChange={(e) => this.props.localUpdate('end_premium', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label for="emailVerified">Email verified</Label>
                                <Input
                                    type="select"
                                    id="emailVerified"
                                    value={this.props.user.email_verified_at ? 'yes' : 'no'}
                                    onChange={(e) => {
                                        const value = e.target.value === 'yes' ? new Date().getTime() : null;
                                        this.props.localUpdate('email_verified_at', value);
                                    }}
                                >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mt-2 mb-4">
                        <Col lg={{ size: 12 }}>
                            <h3>Organization</h3>
                            <p className={style.deviceLimitationParagraph}>
                                Here is the organization and billing group of the currently selected user. You can click
                                on links to access the client admin panel on those entity, but you need to be admin of
                                this organization!
                            </p>
                            <Row>
                                <Col>
                                    <div>
                                        <div>
                                            <span>Organization: </span>{' '}
                                            {user.organization_id && (
                                                <a
                                                    href={
                                                        REACT_APP_CLIENT_ADMIN_PANEL_URL +
                                                        '/' +
                                                        user.organization_id +
                                                        '/welcome'
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {user.organization_title}
                                                </a>
                                            )}
                                            {!user.organization_id && <Badge>No organization</Badge>}
                                        </div>
                                        <span>Billing Group:</span>{' '}
                                        {user.billing_group_id && (
                                            <a
                                                href={
                                                    REACT_APP_CLIENT_ADMIN_PANEL_URL +
                                                    '/' +
                                                    user.organization_id +
                                                    '/billing-group/' +
                                                    user.billing_group_id
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {user.billing_group_title}
                                            </a>
                                        )}
                                        {!user.billing_group_id && <Badge>No billing group</Badge>}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-2 mb-2">
                        <Col lg={{ size: 12 }}>
                            <h3>Device restrictions</h3>
                            <p className={style.deviceLimitationParagraph}>
                                Users should NOT be able to use the same account on many devices.
                            </p>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="maxWebDevices">Max web devices</Label>
                                        <Input
                                            type="number"
                                            id="maxWebDevices"
                                            min="1"
                                            placeholder="Max number of web devices"
                                            value={user.max_web_device || ''}
                                            onChange={(e) => this.props.localUpdate('max_web_device', e.target.value)}
                                        />
                                        <small id="enforceMobileLoginHelp" class="form-text text-muted">
                                            This defines how many sessions can be active on different web browsers at
                                            the same time for this account. Signing in on another browser will logout
                                            the previous one.
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="maxMobileDevices">Max mobile devices</Label>
                                        <Input
                                            type="number"
                                            id="maxMobileDevices"
                                            min="1"
                                            placeholder="Max number of mobile devices"
                                            value={user.max_mobile_device || ''}
                                            onChange={(e) =>
                                                this.props.localUpdate('max_mobile_device', e.target.value)
                                            }
                                        />
                                        <small id="enforceMobileLoginHelp" class="form-text text-muted">
                                            This defines how many mobile devices can be used at the same time for this
                                            account. The user will need to delete old mobile devices in order to sign in
                                            on a new mobile device.
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-2 mb-2">
                        <Col lg={{ size: 12 }}>
                            <h3>Anti-cheat measures</h3>
                            <p className={style.deviceLimitationParagraph}>
                                Multiple users should NOT be able to use the same account. Security codes sent via SMS
                                should prevent this.
                            </p>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="enforceMobileCheck">Enforce mobile verification</Label>
                                        <Input
                                            type="select"
                                            id="enforceMobileCheck"
                                            value={this.props.user.enforce_mobile_check ? 'yes' : 'no'}
                                            onChange={(e) => {
                                                const value = e.target.value === 'yes';
                                                this.props.localUpdate('enforce_mobile_check', value);
                                            }}
                                        >
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </Input>
                                        <small id="enforceMobileLoginHelp" class="form-text text-muted">
                                            This will force the user to verify his phone number at the next action on
                                            the webapp.
                                        </small>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="enforceMobileLogin">Enforce SMS 2FA</Label>
                                        <Input
                                            type="select"
                                            id="enforceMobileLogin"
                                            value={this.props.user.enforce_mobile_login ? 'yes' : 'no'}
                                            onChange={(e) => {
                                                const value = e.target.value === 'yes';
                                                this.props.localUpdate('enforce_mobile_login', value);
                                            }}
                                        >
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </Input>
                                        <small id="enforceMobileLoginHelp" class="form-text text-muted">
                                            This will require the user to type in a 6 digits code received via SMS at
                                            each login, to delete a device, to change his password, his phone number or
                                            email.
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={style.artificialPaddingForNavbar}>
                        <Col lg={{ size: 12 }}>
                            <h3>Force full sync</h3>
                            <p className={style.deviceLimitationParagraph}>
                                Mobile devices are syncing with our servers by doing incremental sync. When a user
                                reports a bug (data is missing on the mobile for example), it can be caused by a bug in
                                the incremental sync, and a "full sync" might be needed to fix the bug. You can trigger
                                here a full sync for this user.
                            </p>

                            <Row>
                                <Col>
                                    <p>
                                        <ul>
                                            <li>
                                                Force full sync enabled at{' '}
                                                {this.displayDate(this.props.user.force_full_sync_enabled_at)}
                                            </li>
                                            <li>
                                                Last sync started at{' '}
                                                {this.displayDate(
                                                    this.props.user.last_started_sync_started_at,
                                                    forceFullSyncColor,
                                                )}{' '}
                                                & finished at{' '}
                                                {this.displayDate(
                                                    this.props.user.last_started_sync_finished_at,
                                                    forceFullSyncColor,
                                                )}
                                                .
                                            </li>
                                            <li>
                                                Last successfull sync finished at:{' '}
                                                {this.displayDate(this.props.user.last_finished_sync_finished_at)}
                                            </li>
                                        </ul>
                                        <Button color="secondary" size="sm" onClick={this.props.refreshUser}>
                                            {refreshingUserDataLoading ? (
                                                <span>
                                                    {' '}
                                                    Refreshing...{' '}
                                                    <Spinner
                                                        style={{
                                                            width: '0.75rem',
                                                            height: '0.75rem',
                                                        }}
                                                    />
                                                </span>
                                            ) : (
                                                <span>Refresh</span>
                                            )}
                                        </Button>
                                        <Button
                                            color="primary"
                                            className="ml-2"
                                            size="sm"
                                            onClick={this.props.forceFullSync}
                                        >
                                            {forceFullSyncLoading ? (
                                                <span>
                                                    {' '}
                                                    Forcing full sync...{' '}
                                                    <Spinner
                                                        style={{
                                                            width: '0.75rem',
                                                            height: '0.75rem',
                                                        }}
                                                    />
                                                </span>
                                            ) : (
                                                <span>Force full sync now</span>
                                            )}
                                        </Button>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <nav className="navbar fixed-bottom navbar-expand-sm navbar-light bg-light">
                    <div className="container">
                        <div>
                            <Button color="primary" outline block onClick={this.props.loginAs}>
                                Login as this user
                            </Button>
                        </div>
                        <div className="ml-2">
                            <Button color="primary" outline block onClick={this.props.loginAsMobile}>
                                Copy login as
                            </Button>
                        </div>
                        <div className="ml-auto">
                            <span className="navbar-text ">
                                {' '}
                                <Button
                                    color="primary"
                                    disabled={this.props.passwordError || loading}
                                    block
                                    onClick={() => this.props.update(this.props.user)}
                                >
                                    {loading ? (
                                        <span>
                                            {' '}
                                            Updating...{' '}
                                            <Spinner
                                                style={{
                                                    width: '1rem',
                                                    height: '1rem',
                                                }}
                                            />
                                        </span>
                                    ) : (
                                        <span>Update user</span>
                                    )}
                                </Button>
                            </span>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default UserFormComponent;

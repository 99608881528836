import React, { Component } from 'react';
import update from 'immutability-helper';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import Team from '../api/Team.js';

class TeamPremium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            license: 'Professional',
            end_premium: '',
        };

        this.updatePremium = this.updatePremium.bind(this);
    }

    updatePremium() {
        var premiumQuota;

        if (
            this.state.license == 'ExtendedTrial' ||
            this.state.license == 'Enterprise' ||
            this.state.license == 'ExtendedEnterprise' ||
            this.state.license == 'Premium'
        ) {
            premiumQuota = 50000;
        } else if (this.state.license == 'Professional') {
            premiumQuota = 1000;
        } else {
            premiumQuota = 0;
        }

        Team.updateEndPremium(this.props.team.id, Date.parse(this.state.end_premium), this.state.license, premiumQuota)
            .then(() => {
                this.props.notificationSystem.addNotification({
                    message: `Updated with success`,
                    level: 'success',
                });
            })
            .catch((err) => {
                this.props.notificationSystem.addNotification({
                    message: `Error while updating :/`,
                    level: 'error',
                });
            });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <p>Update end premium for this team.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="Licence">Licence</Label>
                            <Input
                                type="select"
                                id="Licence"
                                value={this.state.license}
                                onChange={(e) => this.setState({ license: e.target.value })}
                            >
                                <option value="Professional">Professional</option>
                                <option value="Enterprise">Enterprise</option>
                                <option value="ExtendedEnterprise">Extended enterprise</option>
                                <option value="Trial">Trial</option>
                                <option value="ExtendedTrial">Extended trial</option>
                                <option value="LicenceEnded">Licence ended</option>
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for="EndPremium">End Premium</Label>
                            <Input
                                type="date"
                                id="EndPremium"
                                value={this.state.end_premium}
                                onChange={(e) => this.setState({ end_premium: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="Button">Warning, this is not reversible</Label>
                            <br />
                            <Button color="primary" onClick={this.updatePremium}>
                                Update
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default TeamPremium;

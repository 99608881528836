import React, { Component } from 'react';
import update from 'immutability-helper';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

class TeamFeature extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(code, value) {
        if (value === true) {
            this.props.addFeature(code);
        } else {
            this.props.removeFeature(code);
        }
    }

    render() {
        var features = this.props.features || [];

        return (
            <div>
                <Row>
                    <Col>
                        <p>You can here enable features for this team.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('client-admin-statistics') !== -1}
                                    onChange={(event) =>
                                        this.handleChange('client-admin-statistics', event.target.checked)
                                    }
                                />{' '}
                                Client Admin Statistics
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('gis-export') !== -1}
                                    onChange={(event) => this.handleChange('gis-export', event.target.checked)}
                                />{' '}
                                Show "Enable GIS Export" button in webapp
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('bi-datasets-generation') !== -1}
                                    onChange={(event) => this.handleChange('bi-datasets-generation', event.target.checked)}
                                />{' '}
                                Show "Enable BI datasets" button in webapp for project admins
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('bulk-import-tasks') !== -1}
                                    onChange={(event) => this.handleChange('bulk-import-tasks', event.target.checked)}
                                />{' '}
                                Show "Bulk import tasks" button in webapp
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('ifc-file-import') !== -1}
                                    onChange={(event) => this.handleChange('ifc-file-import', event.target.checked)}
                                />{' '}
                                Allow IFC file import in webapp
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('auto-sync-android') !== -1}
                                    onChange={(event) => this.handleChange('auto-sync-android', event.target.checked)}
                                />{' '}
                                Auto sync on Android app
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('auto-sync-ios') !== -1}
                                    onChange={(event) => this.handleChange('auto-sync-ios', event.target.checked)}
                                />{' '}
                                Auto sync on iOS app
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('note-export-templates') !== -1}
                                    onChange={(event) => this.handleChange('note-export-templates', event.target.checked)}
                                />{' '}
                                New task export template
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={features.indexOf('geojson-file-import') !== -1}
                                    onChange={(event) => this.handleChange('geojson-file-import', event.target.checked)}
                                />{' '}
                                Allow GeoJSON file import in webapp
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default TeamFeature;

import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    ButtonGroup,
    Button,
    Nav,
    NavItem,
    NavLink,
    Form,
    FormGroup,
    Label,
    Input,
    TabContent,
    TabPane,
    Table,
} from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import update from 'immutability-helper';
import Tools from '../api/Tools';

class BatchProjectUserComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRole: 3,
            userIds: '',
            projectIds: '',
        };

        this._notificationSystem = null;
        this.addUsersToProject = this.addUsersToProject.bind(this);
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }

    addUsersToProject() {
        var projects = this.state.projectIds.split('\n');
        var users = this.state.userIds.split('\n');

        var validProjects = [];
        var validUsers = [];

        projects.forEach((project) => {
            if (this._valideUuid(project)) {
                validProjects.push(project);
            }
        });

        users.forEach((user) => {
            if (this._valideUuid(user)) {
                validUsers.push(user);
            }
        });

        return Tools.addUsersToProjects(validUsers, validProjects, this.state.selectedRole)
            .then((projectUsers) => {
                projectUsers.forEach((pu) => {
                    if (pu.error_type) {
                        this._notificationSystem.addNotification({
                            message: `One insert failed. ${pu.error_type}`,
                            level: 'error',
                        });
                    } else {
                        this._notificationSystem.addNotification({
                            message: `Project user inserted with success !`,
                            level: 'success',
                        });
                    }
                });

                this.setState({
                    selectedRole: 3,
                    userIds: '',
                    projectIds: '',
                });
            })
            .catch((err) => {
                console.log(err);
                this._notificationSystem.addNotification({
                    message: `Something bad happenned :/`,
                    level: 'error',
                });
            });
    }

    _valideUuid(uuid) {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <p>The goal of this tool is to help you assign lots of users to projects in BulldozAIR.</p>
                    </Col>
                </Row>

                <hr />

                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="projectIds">Users ID :</Label>
                            <Input
                                type="textarea"
                                name="projectIds"
                                placeholder="Enter here user ids (one id per line)"
                                value={this.state.userIds}
                                onChange={(e) => this.setState({ userIds: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="userIds">Projects ID :</Label>
                            <Input
                                type="textarea"
                                name="userIds"
                                placeholder="Enter here project ids (one id per line)"
                                value={this.state.projectIds}
                                onChange={(e) => this.setState({ projectIds: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Role :</Label>
                            <br />
                            <Input
                                type="select"
                                value={this.state.selectedRole}
                                onChange={(e) => this.setState({ selectedRole: e.target.value })}
                            >
                                <option value="1">Observer</option>
                                <option value="2">Reporter</option>
                                <option value="3" selected="selected">
                                    Collaborator
                                </option>
                                <option value="4">Admin</option>
                                <option value="5">Owner</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Button color="primary" onClick={this.addUsersToProject}>
                                Add
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>

                <NotificationSystem ref="notificationSystem" />
            </div>
        );
    }
}

export default BatchProjectUserComponent;

import React, { Component } from 'react';
import Stat from '../api/Stat.js';
import utils from '../utils/utils.js';
import BlueChartComponent from './BlueChartComponent.js';
import ChartComponent from './ChartComponent.js';
import DownloadTeamUsersStatComponent from './DownloadTeamUsersStatComponent';

class TeamStat extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;
        this.state = {};
    }

    componentDidMount() {
        Stat.getByAudience('team', this.props.match.params.team_id, [
            'all_time',
            'this_week',
            'last_week',
            'this_month',
        ]).then((data) => {
            var newState = {};
            data.forEach(function (elem) {
                newState[elem.interval_name] = elem;
            });
            var variation = utils.calculateVariation(newState.this_week, newState.last_week);
            newState.thisWeekGlobalVariation = variation;
            this.setState(newState);
        });
    }

    render() {
        if (!this.state.all_time) return <div></div>;

        return (
            <div className="content-w">
                <div className="content-i">
                    <div className="content-box">
                        <div className="element-wrapper">
                            <div className="user-profile">
                                <div className="up-head-w team-banner">
                                    <div className="up-social"></div>
                                    <div className="up-main-info">
                                        <h1 className="up-header">{this.state.all_time.item.organization_title}</h1>
                                        <h5 className="up-sub-header">{this.state.all_time.item.title}</h5>
                                    </div>
                                    <svg
                                        className="decor"
                                        width="842px"
                                        height="219px"
                                        viewBox="0 0 842 219"
                                        preserveAspectRatio="xMaxYMax meet"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g transform="translate(-381.000000, -362.000000)" fill="#FFFFFF">
                                            <path
                                                className="decor-path"
                                                d="M1223,362 L1223,581 L381,581 C868.912802,575.666667 1149.57947,502.666667 1223,362 Z"
                                            ></path>
                                        </g>
                                    </svg>
                                </div>
                                <div className="up-controls">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="value-pair">
                                                <div className="label">Status:</div>
                                                <div className="value badge badge-pill badge-success">Active</div>
                                            </div>
                                            <div className="value-pair">
                                                <div className="label">Created at:</div>
                                                <div className="value">
                                                    {new Date(this.state.all_time.item.created_at).getFullYear()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <DownloadTeamUsersStatComponent team_id={this.props.match.params.team_id} />
                                        </div>
                                    </div>
                                </div>
                                <div className="up-contents">
                                    <h5 className="element-header">Team Stats</h5>
                                    <div className="row m-b">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-sm-6 b-r b-b">
                                                    <div className="el-tablo centered padded">
                                                        <div className="value">
                                                            {utils.kFormatter(
                                                                this.state.all_time.general_stats.notes_count,
                                                            )}
                                                        </div>
                                                        <div className="label">Notes</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 b-b b-r">
                                                    <div className="el-tablo centered padded">
                                                        <div className="value">
                                                            {utils.kFormatter(
                                                                this.state.all_time.general_stats.projects_count,
                                                            )}
                                                        </div>
                                                        <div className="label">Projects</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 b-r">
                                                    <div className="el-tablo centered padded">
                                                        <div className="value">
                                                            {utils.kFormatter(
                                                                this.state.all_time.general_stats.active_users_count,
                                                            )}
                                                        </div>
                                                        <div className="label">Active Licences</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 b-r">
                                                    <div className="el-tablo centered padded">
                                                        <div className="value">
                                                            {utils.kFormatter(
                                                                this.state.all_time.general_stats.blocks_count,
                                                            )}
                                                        </div>
                                                        <div className="label">Blocks</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="padded">
                                                <div className="element-info-with-icon smaller">
                                                    <div className="element-info-icon">
                                                        <div className="os-icon os-icon-bar-chart-stats-up"></div>
                                                    </div>
                                                    <div className="element-info-text">
                                                        <h5 className="element-inner-header">Created blocks</h5>
                                                        <div className="element-inner-desc">This week</div>
                                                    </div>
                                                </div>
                                                <div className="el-chart-w">
                                                    <ChartComponent blocks={this.state.this_week.blocks} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h5 className="element-header">Total Stats</h5>
                                    <div className="row m-b">
                                        <div className="col-lg-6">
                                            <h5 className="element-inner-header">Notes data</h5>

                                            <div className="table-responsive">
                                                <table className="table table-lightborder dataTable littleNumbers">
                                                    <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Created (This week)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="nowrap">Assignment Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .assignment_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .assignmentBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .assignmentBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .assignmentBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">File Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .file_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .fileBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .fileBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .fileBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Picture Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .picture_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .pictureBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .pictureBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .pictureBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Plan Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .plan_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .planBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .planBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .planBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Priority Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .priority_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .priorityBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .priorityBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .priorityBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Text Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .text_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .textBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .textBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .textBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Date Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .date_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .dateBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .dateBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .dateBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Form Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .form_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .formBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .formBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .formBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Position Blocks</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .position_blocks_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .positionBlockVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .positionBlockVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .positionBlockVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <h5 className="element-inner-header">Notes Data</h5>

                                            <div className="table-responsive">
                                                <table className="table table-lightborder dataTable littleNumbers">
                                                    <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Created (This week)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="nowrap">Docs</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">Not ready yet</div>
                                                                    <div className="trending trending-up-basic"></div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Docs</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">Not ready yet</div>
                                                                    <div className="trending trending-up-basic"></div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Plans</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">Not ready yet</div>
                                                                    <div className="trending trending-up-basic"></div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Tags</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">Not ready yet</div>
                                                                    <div className="trending trending-up-basic"></div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Reports</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .reports_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .reportVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .reportVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .reportVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Active Licence</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">
                                                                        {
                                                                            this.state.this_week.general_stats
                                                                                .active_users_count
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.state.thisWeekGlobalVariation
                                                                                .userVariation >= 0
                                                                                ? 'trending trending-up-basic'
                                                                                : 'trending trending-down-basic'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .userVariation
                                                                            }
                                                                            %
                                                                        </span>
                                                                        <i
                                                                            className={
                                                                                this.state.thisWeekGlobalVariation
                                                                                    .userVariation >= 0
                                                                                    ? 'os-icon os-icon-arrow-2-up'
                                                                                    : 'os-icon os-icon-arrow-2-down'
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="nowrap">Forms Templates</td>
                                                            <td>
                                                                <div className="el-tablo highlight">
                                                                    <div className="value">Not ready yet</div>
                                                                    <div className="trending trending-up-basic"></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="os-tabs-w">
                                        <div className="os-tabs-controls">
                                            <ul className="nav nav-tabs bigger">
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-toggle="tab" href="#tab_sales">
                                                        Block Charts
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#tab_overview">
                                                        Activity
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul className="nav nav-pills smaller hidden-sm-down interval-switcher">
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#">
                                                        Today
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#">
                                                        7 Days
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#">
                                                        14 Days
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-toggle="tab" href="#">
                                                        Last Month
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="tab_sales">
                                                <div className="el-tablo">
                                                    <div className="label">Created Blocks</div>
                                                    <div className="value">
                                                        {this.state.this_month.general_stats.blocks_count}
                                                    </div>
                                                </div>
                                                <div className="el-chart-w">
                                                    <BlueChartComponent blocks={this.state.this_month.blocks} />
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="tab_overview">
                                                <div className="timed-activities padded">
                                                    <div className="timed-activity">
                                                        <div className="ta-date">
                                                            <span>21st Jan, 2017</span>
                                                        </div>
                                                        <div className="ta-record-w">
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>11:55</strong> am
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="">Catherine Faster</a> update note #3
                                                                </div>
                                                            </div>
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>2:34</strong> pm
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="">Jean Guy</a> commented on note #2
                                                                </div>
                                                            </div>
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>7:12</strong> pm
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="">Christian Kebab</a> was assigned to note
                                                                    #90
                                                                </div>
                                                            </div>
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>9:39</strong> pm
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="#">Ben Mosley</a> added a new picture in
                                                                    note #29
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timed-activity">
                                                        <div className="ta-date">
                                                            <span>3rd Feb, 2017</span>
                                                        </div>
                                                        <div className="ta-record-w">
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>9:32</strong> pm
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="#">John Silver</a> updated note #23
                                                                </div>
                                                            </div>
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>5:14</strong> pm
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="">Philip Calaci</a> commented on note #1
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timed-activity">
                                                        <div className="ta-date">
                                                            <span>21st Jan, 2017</span>
                                                        </div>
                                                        <div className="ta-record-w">
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>11:55</strong> am
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="">Francis Cabrel</a> changed the date of
                                                                    note #12
                                                                </div>
                                                            </div>
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>2:34</strong> pm
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="">Patrick Sebastien</a> updated note #12
                                                                </div>
                                                            </div>
                                                            <div className="ta-record">
                                                                <div className="ta-timestamp">
                                                                    <strong>9:39</strong> pm
                                                                </div>
                                                                <div className="ta-activity">
                                                                    <a href="">Rick Astley</a> commented on note #12
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="tab_conversion"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamStat;

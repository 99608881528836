import axios from 'axios';

export default {
    getAudience: function (type, take, skip, orderDir) {
        return axios
            .get(
                process.env.REACT_APP_SERVER_URL +
                    `/admin/stats/${type}?take=${take}&skip=${skip}&order_dir=${orderDir}`,
            )
            .then((data) => data.data);
    },

    getByAudience: function (type, id, intervals) {
        var intervalStr = '';
        intervals.forEach(function (interval) {
            if (intervalStr.length === 0) {
                intervalStr += interval;
            } else {
                intervalStr += `,${interval}`;
            }
        });

        return axios
            .get(process.env.REACT_APP_SERVER_URL + `/admin/stats/${type}/${id}?interval=${intervalStr}`)
            .then((data) => data.data);
    },

    getUsers: function () {
        return axios.get(process.env.REACT_APP_SERVER_URL + `/admin/organizations/users`).then((data) => data.data);
    },

    getUsersStatsInTeam: function (teamId) {
        return axios.get(process.env.REACT_APP_SERVER_URL + `/admin/teams/${teamId}/user_stats`).then((data) => {
            var users = data.data;
            var rows = [];
            users.forEach((user) => {
                if (user === null) return;
                var newRow = user.general_stats;
                newRow.id = user.item.id;
                newRow.firstname = user.item.firstname;
                newRow.lastname = user.item.lastname;
                newRow.email = user.item.email;
                rows.push(newRow);
            });
            return rows;
        });
    },
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Stat from '../api/Stat.js';
import Csv from '../api/Csv';

const headers = {
    id: 'id',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'Email',
    notes_count: 'Notes',
    picture_blocks_count: 'Picture blocks',
    text_blocks_count: 'Text Blocks',
    position_blocks_count: 'Position Blocks',
    assignment_blocks_count: 'Assignment Blocks',
    date_blocks_count: 'Date Blocks',
    priority_blocks_count: 'Priority Blocks',
    form_blocks_count: 'Form Blocks',
    file_blocks_count: 'File Blocks',
    plan_blocks_count: 'Plan Blocks',
    link_blocks_count: 'Link Blocks',
    projects_count: 'Projects',
    reports_count: 'Reports',
    blocks_count: 'Blocks',
};

var headersOrder = [
    'id',
    'firstname',
    'lastname',
    'email',
    'notes_count',
    'picture_blocks_count',
    'text_blocks_count',
    'position_blocks_count',
    'assignment_blocks_count',
    'date_blocks_count',
    'priority_blocks_count',
    'form_blocks_count',
    'file_blocks_count',
    'plan_blocks_count',
    'link_blocks_count',
    'projects_count',
    'reports_count',
    'blocks_count',
];

class DownloadUserListComponent extends Component {
    constructor(props) {
        super(props);
        this.getStats = this.getStats.bind(this);
    }

    getStats() {
        Stat.getUsersStatsInTeam(this.props.team_id).then((rows) => {
            Csv.exportCSVFile(headers, headersOrder, rows, `team-stats-${this.props.team_id}`);
        });
    }

    render() {
        return (
            <a className="btn btn-secondary btn-sm" onClick={this.getStats} style={{ color: 'white' }}>
                <i className="os-icon os-icon-email-forward"></i>
                <span>Download CSV stats</span>
            </a>
        );
    }
}

export default DownloadUserListComponent;

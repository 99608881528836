import axios from 'axios';

export default {
    create: function (organizationId, team) {
        return axios
            .post(process.env.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/teams', team)
            .then((data) => data.data);
    },

    update: function (teamId, team) {
        return axios.patch(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId, team).then((data) => data.data);
    },

    addUsers: function (teamId, emails) {
        return axios
            .post(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/users', emails)
            .then((data) => data.data);
    },

    deleteUser: function (teamId, userId) {
        return axios
            .delete(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/users/' + userId)
            .then((data) => data.data);
    },

    getUsers: function (teamId, take = 50, skip = 0) {
        return axios
            .get(process.env.REACT_APP_SERVER_URL + `/admin/teams/${teamId}/users?take=${take}&skip=${skip}`)
            .then((data) => data.data);
    },

    delete: function (teamId) {
        return axios
            .patch(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId, {
                is_deleted: true,
            })
            .then((data) => data.data);
    },

    updateEndPremium: function (teamId, end_premium, license, premium_quota) {
        return axios
            .patch(process.env.REACT_APP_SERVER_URL + '/admin/teams/' + teamId + '/users', {
                end_premium,
                license,
                premium_quota,
            })
            .then((data) => data.data);
    },
};

import axios from 'axios';

export default {
    setupTwoFactor: function () {
        return axios.post(process.env.REACT_APP_SERVER_URL + '/admin/setup_two_factor').then((data) => data.data);
    },

    verifyTwoFactor: function (code) {
        return axios
            .post(process.env.REACT_APP_SERVER_URL + '/admin/verify_two_factor', {
                two_factor_token: code,
            })
            .then((data) => data.data);
    },

    isTwoFactorEnabled: function () {
        return axios
            .get(process.env.REACT_APP_SERVER_URL + '/admin/two_factor_enabled')
            .then((data) => data.data.enabled);
    },
};

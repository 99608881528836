import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';

class OrganizationSelect extends Component {
    constructor(props) {
        super(props);
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }

    onChangeSelect(option) {
        if (option && option.value) {
            this.props.selectOrganization(option.organization, option.index);
        } else {
            this.props.selectOrganization(null, null);
        }
    }

    render() {
        let selectedOption = null;
        const selectOptions = this.props.organizations.map((organization, index) => {
            const option = {
                label: organization.title,
                value: organization.id,
                index,
                organization,
            };
            if (organization.id === this.props.selectedOrganizationId) {
                selectedOption = option;
            }
            return option;
        });

        return (
            <FormGroup>
                <label htmlFor="organizationSelect">Organization:</label>
                <Select options={selectOptions} value={selectedOption} onChange={this.onChangeSelect} />
            </FormGroup>
        );
    }
}

export default OrganizationSelect;

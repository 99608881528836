/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Col, Input, Label } from 'reactstrap';

class EditTeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.team.title,
        };

        this._onKeyDown = this._onKeyDown.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            title: nextProps.team.title,
        });
    }

    _onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.updateTeam(this.props.team.id, { title: this.state.title });
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>Edit Team</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label for="orgaName" sm={2}>
                                    Name
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="orgaName"
                                        placeholder="Name"
                                        value={this.state.title}
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                        onKeyDown={(e) => this._onKeyDown(e)}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.props.updateTeam(this.props.team.id, { title: this.state.title })}
                        >
                            Update
                        </Button>{' '}
                        <Button color="danger" onClick={() => this.props.deleteTeam(this.props.team.id)}>
                            Delete
                        </Button>
                        <Button color="secondary" onClick={this.props.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default EditTeamModal;

import React, { Component } from 'react';
import ReportTemplate from '../api/ReportTemplate';
import update from 'immutability-helper';
import { Table, Row, Col, Button, Input, FormGroup } from 'reactstrap';

class ReportUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: props.users,
            newEmails: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            users: nextProps.users,
        });
    }

    addUsers() {
        var emails = this.state.newEmails.split('\n');
        var promises = [];
        emails.forEach((email) => {
            promises.push(this.addUser(email));
        });

        return Promise.all(promises).then(() => {
            this.setState({ newEmails: '' });
        });
    }

    addUser(email) {
        return ReportTemplate.assignToUser(this.props.template.id, email)
            .then((reportTemplateUser) => {
                var user = {
                    id: reportTemplateUser.user_id,
                    email: email,
                };

                const newState = update(this.state, {
                    users: { $push: [user] },
                });

                this.setState(newState);
            })
            .catch((result) => {
                this.props.notificationSystem.addNotification({
                    message: `Fail to add user ${email} ... Maybe the user is already assigned/or the user does not exist.`,
                    level: 'error',
                });

                throw result;
            });
    }

    deleteUser(index, userId) {
        return ReportTemplate.removeAssignment(this.props.template.id, userId).then(() => {
            const newState = update(this.state, {
                users: { $splice: [[index, 1]] },
            });

            this.setState(newState);
        });
    }

    handleChange(event) {
        this.setState({ newEmails: event.target.value });
    }

    render() {
        var listUsers = this.state.users.map((user, index) => {
            return (
                <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>
                        <Button outline color="danger" onClick={() => this.deleteUser(index, user.id)}>
                            Delete
                        </Button>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <Row>
                    <Col>
                        <h4>User list</h4>

                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>{listUsers}</tbody>
                        </Table>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Input
                                type="textarea"
                                value={this.state.newEmails}
                                onChange={this.handleChange}
                                placeholder="Add users separated by new lines"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button outline color="success" onClick={this.addUsers}>
                                Add
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ReportUserList;

import React, { Component } from 'react';

import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import UserBatch from './UserBatch';
import BatchProjectUser from './BatchProjectUser';
import TechTeam from './TechTeam';
import classnames from 'classnames';
import Auth from '../../app/Auth';
import User from '../../user/api/User';

const TECH_TEAM_EMAILS = ['maxence@bulldozair.com', 'thibaud@bulldozair.com', 'pierregilles.leymarie@gmail.com'];

class ToolsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            userIsTechTeam: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    async checkIfCurrentUserIsTechTeam() {
        const currentUserId = Auth.getCurrentUserId();

        const user = await User.getById(currentUserId);
        const userIsTechTeam = TECH_TEAM_EMAILS.indexOf(user.email) !== -1;
        this.setState({
            userIsTechTeam,
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    componentDidMount() {
        this.checkIfCurrentUserIsTechTeam();
    }

    render() {
        return (
            <Container className="vertical-offset-20">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => {
                                this.toggle('1');
                            }}
                        >
                            {' '}
                            Batch Users
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => {
                                this.toggle('2');
                            }}
                        >
                            {' '}
                            Batch Project Users
                        </NavLink>
                    </NavItem>
                    {this.state.userIsTechTeam === true && (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => {
                                    this.toggle('3');
                                }}
                            >
                                {' '}
                                Tech team actions
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <UserBatch />
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <BatchProjectUser />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <TechTeam />
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </Container>
        );
    }
}

export default ToolsComponent;

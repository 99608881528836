import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import Select from 'react-select';

class TeamSelect extends Component {
    constructor(props) {
        super(props);
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }

    onChangeSelect(option) {
        if (option && option.value) {
            this.props.selectTeam(option.team, option.index);
        } else {
            this.props.selectTeam(null, null);
        }
    }

    render() {
        let selectedOption = null;
        const selectOptions = this.props.teams.map((team, index) => {
            const option = {
                label: team.title,
                value: team.id,
                index,
                team,
            };
            if (team.id === this.props.selectedTeamId) {
                selectedOption = option;
            }
            return option;
        });

        return (
            <FormGroup>
                <label htmlFor="teamSelect">Team:</label>
                <Select options={selectOptions} value={selectedOption} onChange={this.onChangeSelect} />
            </FormGroup>
        );
    }
}

export default TeamSelect;

import React, { Component } from 'react';

import Chart from 'chart.js';

var fontFamily =
    '"Proxima Nova W01", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';
// set defaults
Chart.defaults.global.defaultFontFamily = fontFamily;
Chart.defaults.global.tooltips.titleFontSize = 14;
Chart.defaults.global.tooltips.titleMarginBottom = 4;
Chart.defaults.global.tooltips.displayColors = false;
Chart.defaults.global.tooltips.bodyFontSize = 12;
Chart.defaults.global.tooltips.xPadding = 10;
Chart.defaults.global.tooltips.yPadding = 8;

var DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

class ChartComponent extends Component {
    componentDidMount() {
        var blocks = this.props.blocks;
        var labels = [];
        var data = [];
        var max = 50;

        blocks.forEach(function (block) {
            var formattedDate = new Date(block.date).toLocaleDateString('en-US', DATE_OPTIONS);
            labels.push(formattedDate);
            data.push(block.blocks_count);
            if (block.blocks_count > max) {
                max = block.blocks_count;
            }
        });

        max *= 1.1;

        var liteLineChart = document.getElementById('liteLineChart');

        var liteLineGradient = liteLineChart.getContext('2d').createLinearGradient(0, 0, 0, 200);
        liteLineGradient.addColorStop(0, 'rgba(30,22,170,0.08)');
        liteLineGradient.addColorStop(1, 'rgba(30,22,170,0)');

        // line chart data
        var liteLineData = {
            labels: labels,
            datasets: [
                {
                    label: 'Blocks',
                    fill: true,
                    lineTension: 0.4,
                    backgroundColor: liteLineGradient,
                    borderColor: '#8f1cad',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#fff',
                    pointBackgroundColor: '#2a2f37',
                    pointBorderWidth: 2,
                    pointHoverRadius: 6,
                    pointHoverBackgroundColor: '#FC2055',
                    pointHoverBorderColor: '#fff',
                    pointHoverBorderWidth: 2,
                    pointRadius: 4,
                    pointHitRadius: 5,
                    data: data,
                    spanGaps: false,
                },
            ],
        };

        // line chart init
        var myLiteLineChart = new Chart(liteLineChart, {
            type: 'line',
            data: liteLineData,
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            display: false,
                            ticks: {
                                fontSize: '11',
                                fontColor: '#969da5',
                            },
                            gridLines: {
                                color: 'rgba(0,0,0,0.0)',
                                zeroLineColor: 'rgba(0,0,0,0.0)',
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                beginAtZero: true,
                                max: max,
                            },
                        },
                    ],
                },
            },
        });

        console.log('Displaying chart');
    }

    render() {
        return <canvas height="100" id="liteLineChart" width="300"></canvas>;
    }
}

export default ChartComponent;
